<template>
  <div>
    <DeleteModal :deleteModal="deleteModal" @init="init()"></DeleteModal>
    <div v-if="isPermission==false">資料讀取中</div>
    <div v-else-if="permission.isView">
      <div v-if="!detail">
        <b-row
          ><b-col>
            <b-form-input
              v-model="search.keywords"
              placeholder="請輸入關鍵字"
              maxlength="100"
            ></b-form-input></b-col
          >
          <b-col>
            <b-form-select
              v-model="search.businessTwoId"
              :options="oneOptions"
              placeholder="請選擇分類"
              @change="
                initTwoOption = true;
                getTwoOption(search.businessTwoId)"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-datepicker
              placeholder="請選擇開始日期"
              v-model="search.startDate"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          ~
          <b-col>
            <b-form-datepicker
              placeholder="請選擇結束日期"
              v-model="search.OverDate"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col cols="1"
            ><b-button @click="init">
              <b-icon icon="search"></b-icon></b-button
          ></b-col>
          <b-col cols="2"
            ><b-button 
              @click="_go('?type='+typeSelect+'&id=null')"
              v-if="permission.isInsert"
              >新增</b-button
            ></b-col
          ></b-row
        >
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>標題</b-th>
              <b-th>分類</b-th>
              <b-th>異動人員</b-th>
              <b-th>異動日期</b-th>
              <b-th v-if="permission.isDelete || permission.isView"
                >修改</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in contentList" :key="item.contentId">
              <b-td>{{ item.title }}</b-td>
              <b-td>{{ item.businessTwoName }}</b-td>
              <b-td>{{ item.modifierName }}</b-td>
              <b-td>{{ dateFormat(item.modifyDate) }}</b-td>
              <b-td v-if="permission.isDelete || permission.isView">
                <b-button
                  v-if="permission.isUpdate"
                  variant="info"
                  class="mr-3"
                  @click="_go('?type='+typeSelect+'&id=' + item.businessId)"
                  >{{ (permission.isUpdate || permission.isInsert)  ? "編輯" : "檢視" }}</b-button
                >
                <a
                  href="javascript:"
                  v-if="permission.isDelete"
                  @click="functionDelete(item)"
                  ><img src="/images/backend/del_icon.png" alt="刪除icon"/></a
              ></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="page mt-2">
          <span class="pagination-total">共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料</span>
          <b-pagination
            class="pagination light-theme simple-pagination"
            v-model="pagination.page"
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </div>
      <div v-else>
        <div class="incsal">
          <div class="btare">
            <div class="backrul">
              <a :href="'#/backend/Business/?type=' + typeSelect">返回查詢結果</a>
            </div>
          </div>
          <b-row>
            <b-col
              ><label for="title">標題*</label>
              <b-form-input
                id="title"
                v-model="contentDetail.title"
                type="text"
                placeholder="請輸入標題"
                required
                maxlength="180"
              ></b-form-input
            ></b-col>
            <b-col>
              <label>分類</label>
              <b-form-select
                v-model="contentDetail.businessTwoId"
                :options="oneOptions"
                placeholder="不分類"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>上架時間*</label>
              <b-form-datepicker 
                v-model="contentDetail.startDate"
                class="mb-2"
              ></b-form-datepicker
            ></b-col>
            <b-col>
              <label>下架時間</label>
              <b-form-datepicker
                v-model="contentDetail.endDate"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col
              ><label>發布狀態*</label>
              <b-form-select
                v-model="contentDetail.deployStatus"
                :options="statusOptions"
              ></b-form-select
            ></b-col>
          </b-row>
          <b-button 
              :variant="isEditor==true?'primary':''"
              @click="isEditor=true"
              >編輯內文</b-button
            >
          <b-button 
              :variant="isEditor==false?'primary':''"
              @click="isEditor=false"
              >轉跳網址</b-button
            >
          <b-row v-if="isEditor==false">
            <b-col
              ><label>相關網址</label>
              <b-form-input
                v-model="contentDetail.url"
                type="text"
                placeholder="請輸入相關網址"
                maxlength="200"
              ></b-form-input
            ></b-col>
            <b-col
              ><label>開啟方式</label>
              <b-form-select
                v-model="contentDetail.linkMethod"
                :options="linkOptions"
              ></b-form-select
            ></b-col>
          </b-row>
          <b-row v-if="isEditor==true">
            <b-col>
              <label>內文</label>
              <TinyMceEditor IsPrivate="false" v-model="contentDetail.description"></TinyMceEditor>
            </b-col>
          </b-row>
          <b-row
            ><b-col>
              <label>建檔人員</label>
              <b-form-input
                disabled="disabled"
                v-model="contentDetail.creatorName"
                class="mb-2"
              ></b-form-input></b-col
            ><b-col>
              <label>建檔日期</label>
              <b-form-datepicker
                disabled="disabled"
                v-model="contentDetail.createDate"
                class="mb-2"
              ></b-form-datepicker></b-col
          ></b-row>
          <b-row
            ><b-col>
              <label>異動人員</label>
              <b-form-input
                disabled="disabled"
                v-model="contentDetail.modifierName"
                class="mb-2"
              ></b-form-input></b-col
            ><b-col>
              <label>異動日期</label>
              <b-form-datepicker
                disabled="disabled"
                v-model="contentDetail.modifyDate"
                class="mb-2"
              ></b-form-datepicker></b-col
          ></b-row>
          <div v-if="permission.isInsert || permission.isUpdate" style="margin-top: 10px">
            <b-button @click="save">儲存</b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>無權限</div>
  </div>
</template>
<script>
  import TinyMceEditor from "../tinymce-editor/tinymce-editor.vue";
  import { getTinymce } from '@tinymce/tinymce-vue/lib/cjs/main/ts/TinyMCE';
  import DeleteModal from "../modal/deleteModal.vue";
  export default {
    components: {
      TinyMceEditor,
      DeleteModal,
    },
    props: [ "contentId", "contentType", "typeSelect" ],
    data() {
      return {
        detail: false,
        isEditor: true,
        search: {
          businessOneId: null,
          businessTwoId: null,
        },
        initSeach: false,
        initOneOption: false,
        initTwoOption: false,
        pagination: {
          perPage: 10,
          page: 1,
          rows: 5,
        },
        contentList: [],
        contentDetail: {},
        oneOptions: [{ value: null, text: "不分類" }],
        twoOptions: [{ value: null, text: "不分類" }],
        deleteModal: {
          id: null,
          show: false,
          typeId: null,
          type: "Business",
        },
        statusOptions: [
          { value: 1, text: '開啟' },
          { value: 2, text: '關閉' },
        ],
        linkOptions: [
          { value: '_self', text: '本頁直接開啟' },
          { value: '_blank', text: '另開新視窗' },
        ],
        apiBusiness: (data) => this.userRequest.post("Eshntu/Business", data),
      };
    },
    computed: {},
    mounted() {
      this.init();
    },
    watch: {
      "$route.query"() {
        this.init();
      },
      "pagination.page"() {
        this.init();
      },
      "isEditor"(){
        if(this.isEditor) {
          this.contentDetail.url = "";
          this.contentDetail.linkMethod = "_self";
        } else {
          this.contentDetail.description = "";
        }
      },
    },
    methods: {
      async init() {
        switch(this.typeSelect)
        {
          case 1:
              //教育訓練
              this.getPermission("PJ202409010009");
              break;
          case 2:
              //健康管理
              this.getPermission("PJ202409010010");
              break;
          case 3:
              //保護計劃
              this.getPermission("PJ202409010011");
              break;
          case 4:
              //實驗場所
              this.getPermission("PJ202409010012");
              break;
          case 5:
              //廢棄物清運
              this.getPermission("PJ202409010013");
              break;
          case 6:
              //災害防救
              this.getPermission("PJ202409010014");
              break;
          case 7:
              //自動檢查
              this.getPermission("PJ202409010015");
              break;
          case 8:
              //其他業務
              this.getPermission("PJ202409010016");
              break;
          case 9:
              //環安衛宣導
              this.getPermission("PJ202409010017");
              break;
          case 10:
              //相關委員會
              this.getPermission("PJ202409010018");
              break;
          case 11:
              //中心簡介
              this.getPermission("PJ202409010019");
              break;
          case 12:
              //法規輯要
              this.getPermission("PJ202409010020");
              break;
          case 13:
              //文件下載
              this.getPermission("PJ202409010021");
              break;
          case 14:
              //駐警隊-關於本隊
              this.getPermission("PJ202409010030");
              break;
          case 15:
              //駐警隊-服務項目
              this.getPermission("PJ202409010031");
              break;
          case 16:
              //駐警隊-常用連結
              this.getPermission("PJ202409010032");
              break;
          case 17:
              //Training
              this.getPermission("PJ202409010025");
              break;
          case 20:
              //About EHS
              this.getPermission("PJ202409010026");
              break;
          case 21:
              //Work Group
              this.getPermission("PJ202409010027");
              break;
          case 18:
              //Health Management
              this.getPermission("PJ202409010035");
              break;
          case 19:
              //Laboratories
              this.getPermission("PJ202409010036");
              break;
        }
        
        if (this.contentId == undefined) {
          window.scrollTo(0, 0);
          this.detail = false;  
          this.initSeach = true; 
          this.initOneOption = false;
          this.initTwoOption = false; 
                        
          try {
            this.contentList = [];
            let jsonReq = {
              TYPE: "LIST",
              business_SELECT: {
                businessTypeId: this.contentType,
                businessOneId: this.typeSelect,
                businessTwoId: this.search.businessTwoId,
                startDate: this.search.startDate,
                endDate: this.search.endDate,
                keywords: this.search.keywords,
                pageIndex: this.pagination.page,
                pageSize: this.pagination.perPage,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Business",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentList = jsonData.outData;
              this.pagination.rows = jsonData.totalCount;

              await this.getSearchOneOption();
            }
          } catch (err) {
              console.log(err);
          }
        } else if (this.contentId == "null") {
          this.detail = true;  
          this.initSeach = false; 
          this.initOneOption = true;
          this.initTwoOption = true;

          this.contentDetail = {
            businessTypeId: this.contentType,
            businessOneId: this.typeSelect,
            businessTwoId: null,
            startDate: new Date().toISOString(),
            linkMethod: '_self',
            deployStatus: 2,
            creatorName: sessionStorage.getItem("LoginName"),
            createDate: new Date(),
            modifierName: sessionStorage.getItem("LoginName"),
            modifyDate: new Date(),
          };

          await this.getOneOption();
        } else {
          this.detail = true;  
          this.initSeach = false; 
          this.initOneOption = true;
          this.initTwoOption = true;

          try {
            this.contentDetail = {};
            let jsonReq = {
              TYPE: "SELECT",
              business_SELECT: {
                businessId: this.contentId,
                businessTypeId: this.contentType,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Business",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentDetail = jsonData;

              if(this.contentDetail.url!='' && this.contentDetail.url!=null) {
                this.isEditor = false;
              } else {
                this.isEditor = true;
                this.setTinymceContent(this.contentDetail.description);
              }

              await this.getOneOption();
            }
          } catch (err) {
              console.log(err);
          }
        }
      },
      functionDelete(item) {
        this.deleteModal.show = true;
        this.deleteModal.id = item.businessId;
        this.deleteModal.typeId = this.contentType;
        this.deleteModal.type = "Business";
      },
      save() {
        if(this.isEditor==true) {
          this.contentDetail.description = this.getTinymceContent();
        }

        if (this.contentId == "null") {
          let json = {
            TYPE: "INSERT",
            business_INSERT: {
              businessTypeId: this.contentType,
              businessOneId: this.contentDetail.businessOneId,
              businessTwoId: this.contentDetail.businessTwoId,
              title: this.contentDetail.title,
              description: this.contentDetail.description,
              url: this.contentDetail.url,
              linkMethod: this.contentDetail.linkMethod,
              startDate: this.contentDetail.startDate,
              endDate: this.contentDetail.endDate,
              deployStatus: this.contentDetail.deployStatus,
            },
          };
          this.FunctionToken(this.InsertContent, json);
        } else {
          let json = {
            TYPE: "UPDATE",
            business_UPDATE: {
              businessId: this.contentId,
              businessTypeId: this.contentType,
              businessOneId: this.contentDetail.businessOneId,
              businessTwoId: this.contentDetail.businessTwoId,
              title: this.contentDetail.title,
              description: this.contentDetail.description,
              url: this.contentDetail.url,
              linkMethod: this.contentDetail.linkMethod,
              startDate: this.contentDetail.startDate,
              endDate: this.contentDetail.endDate,
              deployStatus: this.contentDetail.deployStatus,
            },
          };
          this.FunctionToken(this.UpdateContent, json);
        }
      },
      InsertContent(data) {
        this.apiBusiness(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.$bvToast.toast("新增成功", {
                title: "提示",
                solid: true,
              });
              this.pagination.page = 1;
              this._go("?type=" + this.typeSelect);
            } else {
              alert(JSON.parse(json.Data));
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      UpdateContent(data) {
        this.apiBusiness(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this.$bvToast.toast("更新成功", {
                title: "提示",
                solid: true,
              });
              this._go("?type=" + this.typeSelect);
            } else {
              alert(JSON.parse(json.Data));
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      async getOneOption() {
        try {
            let jsonReq = {
              TYPE: "SELECT_ONE_OPTION",
              business_SELECT: {
                businessTypeId: this.contentType,
                businessOneId: this.typeSelect,
                businessId: this.contentId,
              }
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Business",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.oneOptions = [];

              this.oneOptions.push({ value: null, text: "不分類" });
              if (jsonData.length > 0) {
                jsonData.forEach((x) => {
                  if(this.initOneOption) {
                    if(this.contentId != x.key) {
                      let obj = { value: x.key, text: x.value };
                      this.oneOptions.push(obj);
                    }
                  } else {
                    let obj = { value: x.key, text: x.value };
                    this.oneOptions.push(obj);
                  }
                });
              }
            }
        } catch (err) {
            console.log(err);
        }
      },
      async getSearchOneOption() {
        try {
            let jsonReq = {
              TYPE: "SELECT_ONE_OPTION",
              business_SELECT: {
                businessTypeId: this.contentType,
                businessOneId: this.typeSelect,
              }
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Business",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.oneOptions = [];

              this.oneOptions.push({ value: null, text: "不分類" });
              if (jsonData.length > 0) {
                jsonData.forEach((x) => {
                  if(this.initOneOption) {
                    if(this.contentId != x.key) {
                      let obj = { value: x.key, text: x.value };
                      this.oneOptions.push(obj);
                    }
                  } else {
                    let obj = { value: x.key, text: x.value };
                    this.oneOptions.push(obj);
                  }
                });
              }
            }
        } catch (err) {
            console.log(err);
        }
      },
      getTwoOption(oneId) {
        let json = {
          TYPE: "SELECT_TWO_OPTION",
          business_SELECT: {
            businessTypeId: this.contentType,
            businessOneId: this.typeSelect,
            businessTwoId: oneId,
          }
        };
        this.FunctionToken(this.functionGetTwoOption, json);
      },
      functionGetTwoOption(data) {
        this.apiBusiness(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.twoOptions = [];

              this.twoOptions.push({ value: null, text: "不分類" });
              if (jsonData.length > 0) {
                jsonData.forEach((x) => {
                  if(this.initTwoOption) {
                    if(this.contentId != x.key) {
                      let obj = { value: x.key, text: x.value };
                      this.twoOptions.push(obj);
                    }
                  } else {
                    let obj = { value: x.key, text: x.value };
                    this.twoOptions.push(obj);
                  }
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setTinymceContent(data) {
          setTimeout(function () {
            getTinymce().activeEditor.setContent(data);
          }, 500);
      },
      getTinymceContent() {
          return getTinymce().activeEditor.getContent();
      },
    },
  };
</script>
