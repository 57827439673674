<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left"></SideLeft>

        <div class="inmc_right">
          <!--單元內文介紹區-->
          <div v-if="!detail" class="conartical">
              <div v-if="!pass" class="secretall">
                <div class="articl">
                  <p class="txc_Red">本頁面目前未授權瀏覽～</p></div>
              </div>
              <div v-else class="secretall">
                  <label for="pwd" class="scrstt">請輸入密碼</label>
                  <b-form-input id="pwd"
                    type="password"
                    placeholder="請輸入密碼"
                    v-model="pwd"
                    class="scrtxf"
                  ></b-form-input>
                  
                  <div class="scrbtn">
                    <b-button size="lg" @click="userLogin"
                        >確認</b-button
                      ></div>
              </div>
          </div>
          <div v-else>
            <b-overlay :show="loading" rounded="sm">
              <div class="conartical">
                  <div class="update">最後更新：{{ dateFormat(contentDetail.modifyDate) }}</div>
                  <div class="articl" v-html="contentDetail.description"></div>
              </div>
            </b-overlay>
          </div>
          <!--單元內文介紹區結束-->
        </div>
    </div>  
    <!--主內文區塊結束-->  
      
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/default/NavBar/Header.vue";
import SideLeft from "../../components/default/NavBar/SideLeft.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      loading: false,
      pass: false,
      detail: false,
      Id: null,
      pwd: null,
      header: {
        text1: "加密頁面",
        text2: null,
      },
      sider: {
        index: 99,
        type: 0,
        list: [{ text: "加密頁面", url: "" }],
      },
      left: {
        id: null,
        title: "加密頁面",
        icon: "mnu06.svg",
        color: "bgc_pink",
        list: [],
      },
      footer: {},
      contentDetail: {},
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
  },
  methods: {
    async init() {
      window.scrollTo(0, 0);
      this.detail = false;

      this.Id = this.$route.query.id;
      if(this.Id == undefined)
         this._go("?");
         
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_CHECK",
          private_content_SELECT: {
            account: this.Id,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/PrivateContent",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
          this.pass = true;
        } else 
          this._go("?");
      } catch (err) {
        console.log(err);
      }
    },
    async userLogin() {
      this.loading = true;

      let password = this.pwd;
      this.pwd = null;
      
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_DETAIL",
          private_content_SELECT: {
            account: this.Id,
            pwd: password,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/PrivateContent",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
          this.detail = true;

          let jsonData = JSON.parse(json.Data);
          this.contentDetail = jsonData;

          this.loading = false;
        } else {
          this.pass = false;
          this._go("?");
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
