<template>
  <div>
    <DeleteModal :deleteModal="deleteModal" @init="init()"></DeleteModal>
    <div v-if="isPermission==false">資料讀取中</div>
    <div v-else-if="permission.isView">
      <div v-if="!detail">
        <b-row
          ><b-col>
            <b-form-input
              v-model="search.keywords"
              placeholder="請輸入群組名稱"
              maxlength="50"
            ></b-form-input></b-col
          ><b-col cols="1"
            ><b-button @click="init"> <b-icon icon="search"></b-icon></b-button
          ></b-col>
          <b-col cols="2" v-if="permission.isInsert"
            ><b-button @click="_go('?type=5&id=null')">新增</b-button></b-col
          ></b-row
        >
        <b-container>
          <b-table-simple>
            <b-thead>
              <b-tr>
                <b-th>群組名稱</b-th>
                <b-th>建檔人員</b-th>
                <b-th v-if="permission.isDelete || permission.isView"
                  >修改</b-th
                >
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="item in groupList" :key="item.AG_ID">
                <b-td>{{ item.AG_NAME }}</b-td>
                <b-td>{{ item.creatorName }}</b-td>
                <b-td v-if="permission.isDelete || permission.isView">
                  <b-button
                    class="mr-5"
                    variant="info"
                    @click="_go('?type=5&id=' + item.AG_ID)"
                    >{{ (permission.isUpdate || permission.isInsert)  ? "編輯" : "檢視" }}</b-button
                  >
                  <a
                    href="javascript:"
                    v-if="permission.isDelete"
                    @click="FunctionDelete(item)"
                    ><img src="/images/backend/del_icon.png" alt="刪除icon"
                  /></a>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="page mt-2">
            <span class="pagination-total">共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料</span>
            <b-pagination
              class="pagination light-theme simple-pagination"
              v-model="pagination.page"
              :total-rows="pagination.rows"
              :per-page="pagination.perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </b-container>
      </div>
      <div v-else>
        <div class="text-right">
          <b-btn href="#/backend/Account/?type=5"
            ><b-icon icon="reply"></b-icon> 返回查詢結果</b-btn
          >
        </div>

        <b-row>
          <b-col
            ><b-form-group label="群組名稱*">
              <b-form-input
                v-model="groupDetail.AG_NAME"
                placeholder="請輸入群組名稱"
                maxlength="16"
              ></b-form-input> </b-form-group
          ></b-col>
        </b-row>
        <b-card
          header-class="h5 px-3 py-2"
          no-body
          class="mb-3"
          v-for="(item1, index) in groupOption"
          :key="index"
        >
          <template #header>
            {{ item1.TITLE }}
            <b-button
              @click="
                functionCheckAll(item1)
              "
              >全選</b-button
            >
            <b-button
              @click="
                functionCheckCancel(item1)
              "
              >取消全選</b-button
            >
          </template>
          <b-table-simple hover small caption-top class="mb-0">
            <template v-for="item2 in item1.SubList">
              <template v-if="item2.ThirdList != null">
                <b-tr :key="item2.PJ_ID">
                  <b-th
                    :rowspan="item2.ThirdList.length + 1"
                    class="text-left w-25"
                    >{{ item2.PJ_NAME }}</b-th
                  >
                </b-tr>
                <b-tr v-for="item3 in item2.ThirdList" :key="item3.PJ_ID">
                  <b-th class="text-left w-25">{{ item3.PJ_NAME }}</b-th>
                  <b-td class="w-50">
                    <b-form-checkbox-group v-model="item3.GP_VERIFY_LIST">
                      <b-form-checkbox
                        value="1"
                        @change="setAllCheckStatus(index)"
                        >檢視</b-form-checkbox
                      >
                      <b-form-checkbox
                        value="2"
                        v-if="getShowVerify(item2.PJ_ID)"
                        @change="setAllCheckStatus(index)"
                        >新增/編輯</b-form-checkbox
                      >
                      <b-form-checkbox
                        value="4"
                        v-if="getShowVerify(item2.PJ_ID)"
                        @change="setAllCheckStatus(index)"
                        >刪除</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr :key="item2.PJ_ID">
                  <b-th colspan="2" class="text-left w-50">{{
                    item2.PJ_NAME
                  }}</b-th>
                  <b-td class="w-50">
                    <b-form-checkbox-group v-model="item2.GP_VERIFY_LIST">
                      <b-form-checkbox
                        value="1"
                        @change="setAllCheckStatus(index)"
                        >檢視</b-form-checkbox
                      >
                      <b-form-checkbox
                        value="2"
                        v-if="getShowVerify(item2.PJ_ID)"
                        @change="setAllCheckStatus(index)"
                        >新增/編輯</b-form-checkbox
                      >
                      <b-form-checkbox
                        value="4"
                        v-if="getShowVerify(item2.PJ_ID)"
                        @change="setAllCheckStatus(index)"
                        >刪除</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-td>
                </b-tr>
              </template>
            </template>
          </b-table-simple>
        </b-card>
        <b-card>
          <form>
            <div class="form-row">
              <b-form-group label="建檔人員" class="col-md-6">
                <b-form-input
                  disabled="disabled"
                  v-model="groupDetail.creatorName"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="建檔日期" class="col-md-6">
                <b-form-datepicker
                  disabled="disabled"
                  v-model="groupDetail.createDate"
                ></b-form-datepicker>
              </b-form-group>
            </div>
            <div class="form-row">
              <b-form-group label="異動人員" class="col-md-6">
                <b-form-input
                  disabled="disabled"
                  v-model="groupDetail.modifierName"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="異動日期" class="col-md-6">
                <b-form-datepicker
                  disabled="disabled"
                  v-model="groupDetail.modifyDate"
                ></b-form-datepicker>
              </b-form-group>
            </div>
            <div v-if="permission.isInsert || permission.isUpdate">
              <b-button @click="save">儲存</b-button>
            </div>
          </form>
        </b-card>
      </div>
    </div>
    <div v-else>資料讀取中</div>
  </div>
</template>

<script>
  import DeleteModal from "../modal/deleteModal.vue";
  export default {
    components: {
      DeleteModal,
    },
    data() {
      return {
        selected: [],
        options: [
          { text: "新增", value: 0 },
          { text: "刪除", value: 2 },
          { text: "更新", value: 3 },
        ],
        detail: false,
        search: { selected: 0 },
        searchOptions: [{ value: 0, text: "群組名稱" }],
        groupOption: [],
        groupDetail: {},
        groupList: [],
        pagination: {
          perPage: 8,
          page: 1,
          rows: 5,
        },
        deleteModal: {
          id: null,
          show: false,
          type: "AccountGroup",
        },
        apiProject: (data) => this.userRequest.post("Eshntu/Project", data),
        apiAccountGroup: (data) =>
          this.userRequest.post("Eshntu/AccountGroup", data),
      };
    },
    mounted() {
      this.init();
    },
    watch: {
      "$route.query"() {
        this.init();
      },
      "pagination.page"() {
        this.init();
      },
    },
    methods: {
      async init() {
        this.getPermission("PJ202409010004");
        this.id = this.$route.query.id;
        if (this.id == undefined) {
          this.detail = false;

          try {
            this.groupList = [];
            let jsonReq = {
              TYPE: "LIST",
              ACCOUNT_GROUP_SELECT: {
                keywords: this.search.keywords,
                pageIndex: this.pagination.page,
                pageSize: this.pagination.perPage,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/AccountGroup",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.groupList = jsonData.outData;
              this.pagination.rows = jsonData.totalCount;
            } else {
              alert(JSON.parse(json.Data));
            }
          } catch (err) {
              console.log(err);
          }
        } else if (this.id == "null") {
          this.detail = true;

          try {
            let jsonReq = {
              TYPE: "GROUP_OPTION",
              PROJECT_SELECT: {
                AG_ID: null,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Project",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.groupOption = jsonData;
              this.groupOption.forEach((x) => {
                let initCheckAllStatus = true;
                x.SubList.forEach((y) => {
                  if (y.ThirdList != null) {
                    y.ThirdList.forEach((z) => {
                      if (z.GP_VERIFY_LIST.length != 4) {
                        initCheckAllStatus = false;
                      }
                    });
                  } else {
                    if (y.GP_VERIFY_LIST.length != 4) {
                      initCheckAllStatus = false;
                    }
                  }
                });
                x.checkAll = initCheckAllStatus;
              });
              //console.log(this.groupOption);
            } else {
              alert(JSON.parse(json.Data));
            }
          } catch (err) {
              console.log(err);
          }
          this.groupDetail = {
            creatorName: sessionStorage.getItem("LoginName"),
            modifierName: sessionStorage.getItem("LoginName"),
            createDate: new Date(),
            modifyDate: new Date(),
          };
        } else {
          this.detail = true;
          
          try {
            let jsonReq = {
              TYPE: "GROUP_OPTION",
              PROJECT_SELECT: {
                AG_ID: this.id,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Project",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.groupOption = jsonData;
              this.groupOption.forEach((x) => {
                let initCheckAllStatus = true;
                x.SubList.forEach((y) => {
                  if (y.ThirdList != null) {
                    y.ThirdList.forEach((z) => {
                      if (z.GP_VERIFY_LIST.length != 4) {
                        initCheckAllStatus = false;
                      }
                    });
                  } else {
                    if (y.GP_VERIFY_LIST.length != 4) {
                      initCheckAllStatus = false;
                    }
                  }
                });
                x.checkAll = initCheckAllStatus;
              });
              //console.log(this.groupOption);
            } else {
              alert(JSON.parse(json.Data));
            }
          } catch (err) {
              console.log(err);
          }
          try {
            this.groupDetail = {};
            let jsonReq = {
              TYPE: "SELECT",
              ACCOUNT_GROUP_SELECT: {
                AG_ID: this.id,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/AccountGroup",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.groupDetail = jsonData;
            } else {
              alert(JSON.parse(json.Data));
            }
          } catch (err) {
              console.log(err);
          }
        }
      },
      FunctionDelete(item) {
        this.deleteModal.show = true;
        this.deleteModal.id = item.AG_ID;
        this.deleteModal.title = item.AG_NAME;
      },
      functionCheckAll(obj) {
        obj.SubList.forEach((x) => {
          if (x.ThirdList != null) {
            x.ThirdList.forEach((z) => {
              z.GP_VERIFY_LIST = ["1", "2", "4"];
            });
          } else {
            x.GP_VERIFY_LIST = ["1", "2", "4"];
          }
        });
        /*if (obj.checkAll) {
          obj.checkAll = true;

          obj.SubList.forEach((x) => {
            if (x.ThirdList != null) {
              x.ThirdList.forEach((z) => {
                z.GP_VERIFY_LIST = ["1", "2", "4"];
              });
            } else {
              x.GP_VERIFY_LIST = ["1", "2", "4"];
            }
          });
        } else {
          obj.checkAll = false;
          
          obj.SubList.forEach((x) => {
            if (x.ThirdList != null) {
              x.ThirdList.forEach((z) => {
                z.GP_VERIFY_LIST = [];
              });
            } else {
              x.GP_VERIFY_LIST = [];
            }
          });
        }*/
      },
      functionCheckCancel(obj) {
        obj.SubList.forEach((x) => {
          if (x.ThirdList != null) {
            x.ThirdList.forEach((z) => {
              z.GP_VERIFY_LIST = [];
            });
          } else {
            x.GP_VERIFY_LIST = [];
          }
        });


        /*if (obj.checkAll) {
          obj.checkAll = true;

          obj.SubList.forEach((x) => {
            if (x.ThirdList != null) {
              x.ThirdList.forEach((z) => {
                z.GP_VERIFY_LIST = ["1", "2", "4"];
              });
            } else {
              x.GP_VERIFY_LIST = ["1", "2", "4"];
            }
          });
        } else {
          obj.checkAll = false;
          
          obj.SubList.forEach((x) => {
            if (x.ThirdList != null) {
              x.ThirdList.forEach((z) => {
                z.GP_VERIFY_LIST = [];
              });
            } else {
              x.GP_VERIFY_LIST = [];
            }
          });
        }*/
      },
      setAllCheckStatus(index) {
        
      },
      save() {
        if (this.id == "null") {
          let json = {
            TYPE: "INSERT",
            ACCOUNT_GROUP_INSERT: {
              AG_NAME: this.groupDetail.AG_NAME,
              PERMISSION_LIST: this.groupOption,
            },
          };
          //console.log(json);
          this.FunctionToken(this.InsertAccountGroup, json);
        } else {
          let json = {
            TYPE: "UPDATE",
            ACCOUNT_GROUP_UPDATE: {
              AG_ID: this.id,
              AG_NAME: this.groupDetail.AG_NAME,
              PERMISSION_LIST: this.groupOption,
            },
          };
          //console.log(json);
          this.FunctionToken(this.UpdateAccountGroup, json);
        }
      },
      InsertAccountGroup(data) {
        this.apiAccountGroup(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            //console.log(json);
            if (json.Status) {
              this.$bvToast.toast("新增成功", {
                title: "提示",
                solid: true,
              });
              this.pagination.page = 1;
              this._go("?type=5");
            } else {
              alert(json.Data);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      UpdateAccountGroup(data) {
        this.apiAccountGroup(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            //console.log(json);
            if (json.Status) {
              this.$bvToast.toast("更新成功", {
                title: "提示",
                solid: true,
              });
              this._go("?type=5");
            } else {
              alert(json.Data);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      getShowVerify(index){
        if(index == "PJ202409010005") 
          return false;
        else 
          return true;
      },
    },
  };
</script>
