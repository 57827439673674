<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left"></SideLeft>

        <div class="inmc_right">

            <!--單元內文介紹區-->
            <div class="conartical">
                <div class="stmpall">
                    <div class="mapbk">
                        <div class="mpbtit">主要業務</div>
                        <div class="mpblsbkal">
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=1">教育訓練</a></div>
                                <ul class="mplsmal">
                                    <li class="mplsmh2"
                                        v-for="item in List_1_1" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'1'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=2">健康管理</a></div>
                                <ul class="mplsmal">     
                                    <li class="mplsmh2"
                                        v-for="item in List_1_2" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'2'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=3">保護計畫</a></div>
                                <ul class="mplsmal">      
                                    <li class="mplsmh2"
                                        v-for="item in List_1_3" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'3'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=4">實驗場所</a></div>
                                <ul class="mplsmal">
                                    <li class="mplsmh2"
                                        v-for="item in List_1_4" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'4'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=5">廢棄物清運</a></div>
                                <ul class="mplsmal">
                                    <li class="mplsmh2"
                                        v-for="item in List_1_5" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'5'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=6">災害防救</a></div>
                                <ul class="mplsmal">   
                                    <li class="mplsmh2"
                                        v-for="item in List_1_6" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'6'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=7">自動檢查</a></div>
                                <ul class="mplsmal">
                                    <li class="mplsmh2"
                                        v-for="item in List_1_7" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'7'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=9">環安衛宣導</a></div>
                                <ul class="mplsmal">
                                    <li class="mplsmh2"
                                        v-for="item in List_1_9" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'9'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                              <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=10">相關委員會</a></div>
                                <ul class="mplsmal">      
                                    <li class="mplsmh2"
                                        v-for="item in List_1_10" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'10'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                            <div class="mpblisb">
                                <div class="mplstit"><a href="#/Content?type=8">其它業務</a></div>
                                <ul class="mplsmal">     
                                    <li class="mplsmh2"
                                        v-for="item in List_1_8" 
                                            :key="item.businessId">
                                        <a :href="'#/Content?type='+'8'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mphfall">
                        <div class="mapbk mphelf">
                            <div class="mpbtit">訊息公告</div>
                            <div class="mpblsbkal">
                                <div class="mplstit"><a href="#/News">訊息公告</a></div> 
                            </div>
                        </div>

                        <div class="mapbk mphelf">
                            <div class="mpbtit">中心簡介</div>
                            <div class="mpblsbkal">
                                <div class="mplstit"
                                    v-for="item in List_3_11" 
                                        :key="item.businessId">
                                    <a :href="'#/About?type='+'11'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                                <div class="mplstit"><a href="#/Power">數位電表</a></div> 
                            </div>
                        </div>
                        
                        <div class="mapbk mphelf">
                            <div class="mpbtit">法規輯要</div>
                            <div class="mpblsbkal">
                                <div class="mplstit"
                                    v-for="item in List_4_12" 
                                        :key="item.businessId">
                                    <a :href="'#/About?type='+'12'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                            </div>
                        </div>
                        
                        <div class="mapbk mphelf">
                            <div class="mpbtit">文件下載</div>
                            <div class="mpblsbkal">
                                <div class="mplstit"
                                    v-for="item in List_5_13" 
                                        :key="item.businessId">
                                    <a :href="'#/About?type='+'13'+'&L1='+item.key+'&id='+item.key">{{ item.value }}</a></div> 
                            </div>
                        </div>
                    
                    </div>
                    
                </div>    
                      
            </div>
            <!--單元內文介紹區結束-->
        </div>
    </div>  
    <!--主內文區塊結束-->  
        
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/default/NavBar/Header.vue";
import SideLeft from "../../components/default/NavBar/SideLeft.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      detail: false,
      loading: false,
      header: {
        text1: "網站導覽",
        text2: null,
      },
      sider: {
        index: 2,
        type: 0,
        list: [{ text: "網站導覽", url: "" }],
      },
      left: {
        title: "網站導覽",
        icon: "mnu07.svg",
        color: "bgc_blue",
        list: [],
      },
      List_1_1: [],
      List_1_2: [],
      List_1_3: [],
      List_1_4: [],
      List_1_5: [],
      List_1_6: [],
      List_1_7: [],
      List_1_8: [],
      List_1_9: [],
      List_1_10: [],
      List_3_11: [],
      List_4_12: [],
      List_5_13: [],
      footer: {},
      apiBusiness: (data) => this.userRequest.post("Eshntu/Business", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
  },
  methods: {
    async init() {
        await this.getContentList_1_1();
        await this.getContentList_1_2();
        await this.getContentList_1_3();
        await this.getContentList_1_4();
        await this.getContentList_1_5();
        await this.getContentList_1_6();
        await this.getContentList_1_7();
        await this.getContentList_1_8();
        await this.getContentList_1_9();
        await this.getContentList_1_10();
        await this.getContentList_3_11();
        await this.getContentList_4_12();
        await this.getContentList_5_13();
    },
    async getContentList_1_1() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 1,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_1 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_2() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 2,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_2 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_3() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 3,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_3 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_4() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 4,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_4 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_5() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 5,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_5 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_6() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 6,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_6 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_7() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 7,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_7 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_8() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 8,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_8 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_9() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 9,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_9 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_1_10() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 1,
            businessOneId: 10,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_1_10 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_3_11() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 3,
            businessOneId: 11,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_3_11 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_4_12() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 4,
            businessOneId: 12,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_4_12 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getContentList_5_13() {
      try {
        this.contentDetail = {};
        let jsonReq = {
          TYPE: "HOME_ONE_LEFT",
          business_SELECT: {
            businessTypeId: 5,
            businessOneId: 13,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            if (jsonData.length > 0) {
              this.List_5_13 = jsonData;
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
