<template>
  <div class="mconarall">
    <Header :header="header"></Header>
    <div class="infall">
      <Businss_1
        v-if="typeSelect==1"
        :contentId="contentId"
        :typeSelect="1"
        :contentType="contentType"></Businss_1>
      <Businss_2
        v-if="typeSelect==2"
        :contentId="contentId"
        :typeSelect="2"
        :contentType="contentType"></Businss_2>
      <Businss_3
        v-if="typeSelect==3"
        :contentId="contentId"
        :typeSelect="3"
        :contentType="contentType"></Businss_3>
      <Businss_4
        v-if="typeSelect==4"
        :contentId="contentId"
        :typeSelect="4"
        :contentType="contentType"></Businss_4>
      <Businss_5
        v-if="typeSelect==5"
        :contentId="contentId"
        :typeSelect="5"
        :contentType="contentType"></Businss_5>
      <Businss_6
        v-if="typeSelect==6"
        :contentId="contentId"
        :typeSelect="6"
        :contentType="contentType"></Businss_6>
      <Businss_7
        v-if="typeSelect==7"
        :contentId="contentId"
        :typeSelect="7"
        :contentType="contentType"></Businss_7>
      <Businss_8
        v-if="typeSelect==8"
        :contentId="contentId"
        :typeSelect="8"
        :contentType="contentType"></Businss_8>
      <Businss_9
        v-if="typeSelect==9"
        :contentId="contentId"
        :typeSelect="9"
        :contentType="contentType"></Businss_9>
      <Businss_10
        v-if="typeSelect==10"
        :contentId="contentId"
        :typeSelect="10"
        :contentType="contentType"></Businss_10>
      <Businss_11
        v-if="typeSelect==11"
        :contentId="contentId"
        :typeSelect="11"
        :contentType="contentType"></Businss_11>
      <Businss_12
        v-if="typeSelect==12"
        :contentId="contentId"
        :typeSelect="12"
        :contentType="contentType"></Businss_12>
      <Businss_13
        v-if="typeSelect==13"
        :contentId="contentId"
        :typeSelect="13"
        :contentType="contentType"></Businss_13>
      <Businss_14
        v-if="typeSelect==14"
        :contentId="contentId"
        :typeSelect="14"
        :contentType="contentType"></Businss_14>
      <Businss_15
        v-if="typeSelect==15"
        :contentId="contentId"
        :typeSelect="15"
        :contentType="contentType"></Businss_15>
      <Businss_16
        v-if="typeSelect==16"
        :contentId="contentId"
        :typeSelect="16"
        :contentType="contentType"></Businss_16>
      <Businss_17
        v-if="typeSelect==17"
        :contentId="contentId"
        :typeSelect="17"
        :contentType="contentType"></Businss_17>
      <Businss_18
        v-if="typeSelect==18"
        :contentId="contentId"
        :typeSelect="18"
        :contentType="contentType"></Businss_18>
      <Businss_19
        v-if="typeSelect==19"
        :contentId="contentId"
        :typeSelect="19"
        :contentType="contentType"></Businss_19>
      <Businss_20
        v-if="typeSelect==20"
        :contentId="contentId"
        :typeSelect="20"
        :contentType="contentType"></Businss_20>
      <Businss_21
        v-if="typeSelect==21"
        :contentId="contentId"
        :typeSelect="21"
        :contentType="contentType"></Businss_21>
    </div>
  </div>
</template>

<script>
import Header from "../../components/admin/NavBar/Header.vue";
import Businss_1 from "../../components/admin/Business/Content.vue";
import Businss_2 from "../../components/admin/Business/Content.vue";
import Businss_3 from "../../components/admin/Business/Content.vue";
import Businss_4 from "../../components/admin/Business/Content.vue";
import Businss_5 from "../../components/admin/Business/Content.vue";
import Businss_6 from "../../components/admin/Business/Content.vue";
import Businss_7 from "../../components/admin/Business/Content.vue";
import Businss_8 from "../../components/admin/Business/Content.vue";
import Businss_9 from "../../components/admin/Business/Content.vue";
import Businss_10 from "../../components/admin/Business/Content.vue";
import Businss_11 from "../../components/admin/Business/Content.vue";
import Businss_12 from "../../components/admin/Business/Content.vue";
import Businss_13 from "../../components/admin/Business/Content.vue";
import Businss_14 from "../../components/admin/Business/Content.vue";
import Businss_15 from "../../components/admin/Business/Content.vue";
import Businss_16 from "../../components/admin/Business/Content.vue";
import Businss_17 from "../../components/admin/Business/Content.vue";
import Businss_18 from "../../components/admin/Business/Content.vue";
import Businss_19 from "../../components/admin/Business/Content.vue";
import Businss_20 from "../../components/admin/Business/Content.vue";
import Businss_21 from "../../components/admin/Business/Content.vue";
export default {
  components: {
    Header,
    Businss_1,
    Businss_2,
    Businss_3,
    Businss_4,
    Businss_5,
    Businss_6,
    Businss_7,
    Businss_8,
    Businss_9,
    Businss_10,
    Businss_11,
    Businss_12,
    Businss_13,
    Businss_14,
    Businss_15,
    Businss_16,
    Businss_17,
    Businss_18,
    Businss_19,
    Businss_20,
    Businss_21,
  },
  data() {
    return {
      header: {
        text1: '環安衛中心',
        text2: '主要業務'
      },
      typeSelect: null,
      contentType: null,
      contentId: null,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.query'() {
      this.init()
    }
  },
  methods: {
    init() {
      this.contentId = this.$route.query.id;
      let type = this.$route.query.type;
      if (type == undefined) {
        type = 0
      }
      this.typeSelect = parseInt(type);
      this.setActive();
    },
    setActive() {
      switch (this.typeSelect) {
        case 1:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '教育訓練';
          this.contentType = 1;
          break;
        case 2:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '健康管理';
          this.contentType = 1;
          break;
        case 3:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '保護計劃';
          this.contentType = 1;
          break;
        case 4:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '實驗場所';
          this.contentType = 1;
          break;
        case 5:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '廢棄物清運';
          this.contentType = 1;
          break;
        case 6:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '災害防救';
          this.contentType = 1;
          break;
        case 7:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '自動檢查';
          this.contentType = 1;
          break;
        case 8:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '其他業務';
          this.contentType = 1;
          break;
        case 9:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '環安衛宣導';
          this.contentType = 1;
          break;
        case 10:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '相關委員會';
          this.contentType = 1;
          break;
        case 11:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '中心簡介';
          this.contentType = 3;
          break;
        case 12:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '法規輯要';
          this.contentType = 4;
          break;
        case 13:
          this.header.text1 = '環安衛中心';
          this.header.text2 = '文件下載';
          this.contentType = 5;
          break;
        case 14:
          this.header.text1 = '駐警隊';
          this.header.text2 = '關於本隊';
          this.contentType = 6;
          break;
        case 15:
          this.header.text1 = '駐警隊';
          this.header.text2 = '服務項目';
          this.contentType = 7;
          break;
        case 16:
          this.header.text1 = '駐警隊';
          this.header.text2 = '常用連結';
          this.contentType = 8;
          break;
        case 17:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Training';
          this.contentType = 9;
          break;
        case 20:
            this.header.text1 = '環安衛中心英文版';
            this.header.text2 = 'About EHS';
          this.contentType = 10;
            break;
        case 21:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Work Group';
          this.contentType = 11;
          break;
        case 18:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Health Management';
          this.contentType = 9;
          break;
        case 19:
          this.header.text1 = '環安衛中心英文版';
          this.header.text2 = 'Laboratories';
          this.contentType = 9;
          break;
      }
    },
  }
};
</script>