<template>
  <div>
    <!--fatfooter區域-->
    <!--<div class="fatfooter">
        <div class="fatfcon">
            <div class="fatbck">
                <div class="fatfh1">Main</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">Training</a></div> 
                    <div class="fatfh2"><a href="#">Health Management</a></div>
                    <div class="fatfh2"><a href="#">Laboratories</a></div>  
                </div>
            </div>
            
            <div class="fatbck">
                <div class="fatfh1">Latest News</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">News</a></div> 
                </div>
            </div>
            
            <div class="fatbck">
                <div class="fatfh1">About EHS</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">關於本中心</a></div> 
                    <div class="fatfh2"><a href="#">我們的工作群</a></div> 
                    <div class="fatfh2"><a href="#">業務職掌</a></div> 
                </div>
            </div>
            
            <div class="fatbck">
                <div class="fatfh1">Work Group</div>
                <div class="fatfh2al">
                    <div class="fatfh2"><a href="#">關於本中心</a></div> 
                    <div class="fatfh2"><a href="#">我們的工作群</a></div> 
                    <div class="fatfh2"><a href="#">業務職掌</a></div> 
                </div>
            </div>
        </div>    
    </div>-->
    <!--fatfooter區域結束-->
    
    <div class="footer">
        <div class="fotcon">
            <div class="fotc_left">
                <div class="ftntulog"><img src="/images/ntulogo.svg" alt=""/></div>
                <div class="ftmlog"><img src="/images/footerLog.svg" alt=""/></div>
            </div>
            <div class="fotc_right">
                Copyright 2024. All Rights Reserved. <br>
                No. 1, Sec. 4, Roosevelt Rd., Taipei 106319, Taiwan (R.O.C.) <br>
                Tel：+886-2-3366-3366 / Fax：+886-2-2362-7651 / E-mail: epc@ntu.edu.tw<br>
                Total Count：{{ totalCount }} <span v-if="!footer.index"> / Page Count：{{ pageCount }}</span> &nbsp; &nbsp;&nbsp;Update Date：{{ dateFormatFloat(updateDate) }}<br>
                Best viewed with 1920 x 1080 resolution.
            </div>
        </div>
        <div class="illpc02" v-if="!footer.index"><img src="/images/illpc02.png" alt=""/></div>
    </div>
    <div class="topbar">
        <div class="tpbrbox06"></div>
        <div class="tpbrbox05"></div>
        <div class="tpbrbox04"></div>
        <div class="tpbrbox03"></div>
        <div class="tpbrbox02"></div>
        <div class="tpbrbox01"></div>      
    </div>
  </div>
</template>

<script>
var date = new Date();
var dateFormat =
  date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
export default {
  data() {
    return {
      totalCount: 1,
      pageCount: 1,
      updateDate: dateFormat,
      pageUrl: null,
    };
  },
  props: ["footer"],
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    async init() {
      this.pageUrl = this.$route.path;
      const classiePlugin = document.createElement("script");
      classiePlugin.setAttribute(
        "src",
        "/js/classie.js"
      );
      classiePlugin.async = true;
      document.head.appendChild(classiePlugin);

      const demoPlugin = document.createElement("script");
      demoPlugin.setAttribute(
        "src",
        "/js/demo1.js"
      );
      demoPlugin.async = true;
      document.head.appendChild(demoPlugin);  

      try {
        let jsonReq = {
          TYPE: "HOME_UPDATE_COUNT",
          calculate_view_SELECT: {
            type: 1,
            page: this.pageUrl,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/CalculateView",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) { 
          try {
            let jsonReq = {
              TYPE: "HOME_SELECT",
              calculate_view_SELECT: {
                type: 1,
                page: this.pageUrl,
              },
            };
            let resToken = await this.userRequest.post(
              "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
              "Eshntu/CalculateView",
              jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
                let jsonData = JSON.parse(json.Data);
                this.totalCount = jsonData.totalCount;
                this.pageCount = jsonData.outData;
            }
          } catch (err) {
            console.log(err);
          }
        }
      } catch (err) {
        console.log(err);
      }

      try {
        let jsonReq = {
          TYPE: "HOME_SELECT",
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/BrowseLog",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
          this.updateDate = json.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
