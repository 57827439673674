<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left"></SideLeft>

        <div class="inmc_right">
          <div v-if="!detail">
            <!--單元內文介紹區-->
            <div class="conartical">
            
              <div class="nwstpbr">
                  <div class="nwsselbar">
                    <b-form-select
                      id="searchTag"
                      class="nwsel"
                      v-model="search.tag"
                      :options="tagOptions"
                      placeholder="All Tag"
                      @change="GetContentSearch(search.tag, search.classic)"
                    ></b-form-select>
                    <b-form-select
                      id="searchClass"
                      class="nwsel"
                      v-model="search.classic"
                      :options="classOptions"
                      placeholder="All Category"
                      @change="GetContentSearch(search.tag, search.classic)"
                    ></b-form-select>
                </div>

                <div class="nwtbsstx">
                  Page.{{Math.ceil(pagination.rows/pagination.perPage)}} / {{pagination.rows}}
                </div>
              </div>
  
              <div class="nwstable">
                <div class="nwstitle">
                    <div class="nwstb01">Date</div>
                    <div class="nwstb02">Title</div>
                    <div class="nwstb03">Category</div>
                </div>
                <div class="nwscbar" v-if="loading">
                  <b-spinner label="Spinning"></b-spinner>
                </div>
                <div class="nwscbar" v-else-if="List.length == 0" >
                    <div class="nwstb01"></div>
                    <div class="nwstb02">
                      Not Data
                    </div>
                    <div class="nwstb03"></div>
                </div>
                <div v-else 
                  v-for="item in List" :key="item.contentId"
                  class="nwscbar"
                >
                  <a
                    :href="'#/EN/News/?id=' + item.contentId + (tag==null?'':'&tag='+tag)"
                    title="Open Self"
                  >
                    <div class="nwstb01">{{ dateFormat(item.createDate) }}</div>
                    <div class="nwstb02">
                      {{ item.title }}
                      <span v-if="item.contentTagName.includes('Important')" class="nwtg_impt">Important</span>
                      <span v-if="item.contentTagName.includes('Outside')" class="nwtg_out">Outside</span>
                    </div>
                    <div class="nwstb03">{{ item.contentClassName }}</div>
                  </a>
                </div>

                <!--頁碼-->
                <div class="twpage">
                  <div class="navigation">
                    <b-pagination
                      class="pagination light-theme simple-pagination"
                      v-model="pagination.page"
                      :total-rows="pagination.rows"
                      :per-page="pagination.perPage"
                      aria-controls="my-table"
                    ></b-pagination>
                  </div>
                </div>
              </div>
            </div>
            <!--單元內文介紹區結束-->
          </div>

          <div v-else>
            <!--單元內文介紹區-->
            <div class="conartical">
              <div class="articl">
                <div class="nwsh1">{{ newsDetail.title }}</div> 
                <div class="nwsarcd">
                    {{ dateFormat(newsDetail.createDate) }}
                    <span class="nwtg">【{{ newsDetail.contentClassName }}】</span>
                    <span v-if="newsDetail.contentTagName.includes('Important')" class="nwtg_impt">Important</span>
                    <span v-if="newsDetail.contentTagName.includes('Outside')" class="nwtg_out">Outside</span>
                </div>
                <div v-html="newsDetail.description" class="artcblk"></div>
              </div> 
                
              <div class="backbtn"><a :href="'#/EN/News'+(this.tag==null?'':'?tag='+this.tag)">Back</a></div>
            </div>
            <!--單元內文介紹區結束-->
          </div>

        </div>

    </div>  
    <!--主內文區塊結束-->  
  
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../../components/default/NavBar_EN/Header.vue";
import SideLeft from "../../../components/default/NavBar_EN/SideLeft.vue";
import SideBar from "../../../components/default/NavBar_EN/SideBar.vue";
import Footer from "../../../components/default/NavBar_EN/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      detail: false,
      loading: false,
      tag: null,
      header: {
        text1: "Latest News",
        text2: null,
      },
      sider: {
        index: 2,
        type: 0,
        list: [{ text: "Latest News", url: "" }],
      },
      left: {
        id: null,
        title: "Latest News",
        icon: "mnu02.svg",
        color: "bgc_green",
        list: [],
      },
      footer: {},
      pagination: {
        perPage: 10,
        page: 1,
        rows: null,
      },
      search: {
        tag: null,
        classic: null,
      },
      List: [],
      tagOptions: [],
      classOptions: [],
      newsDetail: {
        title: "",
      },
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.tag = this.$route.query.tag;

      let id = this.$route.query.id;
      if (id == undefined) {        
        window.scrollTo(0, 0);
        
        this.search = {
          tag: this.tag??null,
          classic: null,
        }
        
        await this.GetContentSearch(this.tag, null);
        
        await this.getClassOption();
        await this.getTagOption();
      } else {
        this.detail = true;

        try {
          this.newsDetail = {};
          let jsonReq = {
            TYPE: "HOME_DETAIL",
            content_SELECT: {
              contentId: id,
              contentTypeId: 4,
            },
          };
          let resToken = await this.userRequest.post(
            "Eshntu/Token"
          ); 
          jsonReq.Token = resToken.data;
          let res = await this.userRequest.post(
            "Eshntu/Content",
            jsonReq
          );
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.newsDetail = jsonData;
            
            if(this.newsDetail.url!=null) {
              if(this.newsDetail.linkMethod == "_self") {
                window.location.href = "#"+this.newsDetail.url.replace("/#/","").replace("#/","");
              } else {
                window.open(this.newsDetail.url);
                this.$router.go(-1);
              }
            }

            this.sider.list = [
                            { text: "Latest News", url: "#/EN/News"+(this.tag==null?'':'?tag='+this.tag) },
                            { text: this.newsDetail.title, url: "" }
                          ];
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async GetContentSearch(tag, classic) {
      this.detail = false;
      this.loading = true;

      try {
        this.List = [];
        let jsonReq = {
          TYPE: "HOME_LIST",
          content_SELECT: {
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
            contentTypeId: 4,
            contentTagId: tag==0?null:tag,
            contentClassId: classic,
            important: tag==0?false:null,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Content",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            
            this.List = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
            this.loading = false;

            this.sider.list = [{ text: "Latest News", url: "" }];
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getClassOption() {
      try {
        let jsonReq = {
          TYPE: "SELECT_OPTION",
          content_type_SELECT: {
            contentClassId: 5,
          }
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/ContentType",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.classOptions = [];

            this.classOptions.push({ value: null, text: "ALL Category" });
            if (jsonData.length > 0) {
              jsonData.forEach((x) => {
                let obj = { value: x.key, text: x.value };
                this.classOptions.push(obj);
              });
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getTagOption() {
      try {
        let jsonReq = {
          TYPE: "SELECT_OPTION",
          content_type_SELECT: {
            contentClassId: 6,
          }
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/ContentType",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.tagOptions = [];

            this.tagOptions.push({ value: null, text: "ALL Tag" });
            if (jsonData.length > 0) {
              jsonData.forEach((x) => {
                let obj = { value: x.key, text: x.value };
                this.tagOptions.push(obj);
              });
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
