<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider" :option="mainOptions"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left" :main="4" :type="type" :L1="L1" :id="Id"></SideLeft>

        <div class="inmc_right">
            <div class="cntitle">
              <div>{{contentDetail.businessTwoName??contentDetail.title}}</div>     
            </div> 
            <SideRight :left="left" :main="4" :type="type" :L1="L1" :L2="L2" :id="Id"></SideRight>
            
            <!--單元內文介紹區-->
            <b-overlay :show="loading" rounded="sm">
              <div class="conartical">
                  <div class="update">最後更新：{{ dateFormat(contentDetail.modifyDate) }}</div>
                  <div class="articl" v-html="contentDetail.description"></div>
              </div>
            </b-overlay>
            <!--單元內文介紹區結束-->
        </div>
    </div>  
    <!--主內文區塊結束-->  
      
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/default/NavBar/Header.vue";
import SideLeft from "../../components/default/NavBar/SideLeft.vue";
import SideRight from "../../components/default/NavBar/SideRight.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    SideRight,
    Header,
    Footer,
  },
  data() {
    return {
      loading: false,
      type: null,
      L1: null,
      L2: null,
      Id: null,
      header: {
        text1: "法規輯要",
        text2: null,
      },
      sider: {},
      left: {},
      footer: {},
      contentDetail: {},
      mainOptions: [],
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.type = this.$route.query.type;
      this.L1 = this.$route.query.L1; 
      this.L2 = this.$route.query.L2; 
      this.sider = {
          index: 4,
          type: this.type,
          list: [{ text: "法規輯要", url: "" }],
      };

      this.left = {
          id: 4,
          url: "Law",
          title: "法規輯要",
          icon: "mnu04.svg",
          color: "bgc_org",
          list: [],
        };
        
      this.Id = this.$route.query.id;
      if (this.Id == undefined) {  
        window.scrollTo(0, 0);

        try {
          this.contentDetail = {};
          let jsonReq = {
            TYPE: "HOME_FIRST_DETAIL",
            business_SELECT: {
              businessTypeId: 4,
              businessOneId: this.type,
            },
          };
          let resToken = await this.userRequest.post(
            "Eshntu/Token"
          ); 
          jsonReq.Token = resToken.data;
          let res = await this.userRequest.post(
            "Eshntu/Business",
            jsonReq
          );
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.contentDetail = jsonData;
            this.L1 = this.contentDetail.businessOneId;
            this.L2 = this.contentDetail.businessTwoId;
            this.Id = this.contentDetail.businessId; 
            
            if(this.contentDetail.url!=null) {
              if(this.contentDetail.linkMethod == "_self") {
                window.location.href = "#"+this.contentDetail.url.replace("/#/","").replace("#/","");
              } else {
                window.open(this.contentDetail.url);
                this.$router.go(-1);
              }
            }
            
            this.loading = false;
            this._go(this.$route.path+"?type="+this.type+"&L1="+this.Id+"&id="+this.Id);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.loading = true;

        try {
          this.contentDetail = {};
          let jsonReq = {
            TYPE: "HOME_DETAIL",
            business_SELECT: {
              businessId: this.Id,
              businessTypeId: 4,
            },
          };
          let resToken = await this.userRequest.post(
            "Eshntu/Token"
          ); 
          jsonReq.Token = resToken.data;
          let res = await this.userRequest.post(
            "Eshntu/Business",
            jsonReq
          );
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.contentDetail = jsonData;
            
            if(this.contentDetail.url!=null) {
              if(this.contentDetail.linkMethod == "_self") {
                window.location.href = "#"+this.contentDetail.url.replace("/#/","").replace("#/","");
              } else {
                window.open(this.contentDetail.url);
                this.$router.go(-1);
              }
            }
            
            this.loading = false;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
