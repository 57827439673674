<template>
  <div>
    <div v-if="isPermission==false">資料讀取中</div>
    <div v-else-if="permission.isView">
      <b-row
        ><b-col>
          <b-form-input
            v-model="search.operationName"
            placeholder="請輸入事件名稱"
            maxlength="20"
          ></b-form-input
        ></b-col>
        <b-col>
          <b-form-input
            v-model="search.userName"
            placeholder="請輸入使用者名稱"
            maxlength="20"
          ></b-form-input
        ></b-col>
        <b-col>
          <b-form-datepicker
            placeholder="請選擇開始日期"
            v-model="search.startDate"
            class="mb-2"
          ></b-form-datepicker>
        </b-col>
        ~
        <b-col>
          <b-form-datepicker
            placeholder="請選擇結束日期"
            v-model="search.OverDate"
            class="mb-2"
          ></b-form-datepicker>
        </b-col>
        <b-col
          ><b-button @click="init"> <b-icon icon="search"></b-icon></b-button
        ></b-col>
      </b-row>
      <b-container>
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th>事件名稱</b-th>
              <b-th>單元</b-th>
              <b-th>使用者</b-th>
              <b-th>事件日期時間</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="item in logList" :key="item.logId">
              <b-td>
                <a href="javascript:void(0)"
                    @click="getModelShow(item)"
                  >{{ item.operationName }}</a></b-td>
              <b-td>{{ getType(item.parameterName, item.parameterId) }}</b-td>
              <b-td>{{ item.userName }}</b-td>
              <b-td>{{ datetimeFormat(item.updateTime) }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="page mt-2">
            <span class="pagination-total">共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料</span>
          <b-pagination
            class="pagination light-theme simple-pagination"
            v-model="pagination.page"
            :total-rows="pagination.rows"
            :per-page="pagination.perPage"
            aria-controls="my-table"
          ></b-pagination>
        </div>
      </b-container>
    </div>
    <div v-else>無權限</div>
    <b-modal v-model="model.show" :title="model.title">
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click="getModelHide"
        >
          確定
        </button>
      </template>
      <p class="my-4" v-html="model.text" />
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      logList: [],
      search: { selected: 0, keywords: null, startDate: null, OverDate: null },
      searchOptions: [
        { value: 0, text: '事件名稱' },
        { value: 1, text: '使用者' },
        { value: 2, text: '時間區間' },
      ],
      pagination: {
        perPage: 8,
        page: 1,
        rows: 5,
      },
      classOptions: [],
      tagOptions: [],
      statusOptions: [
        { value: 1, text: '開啟' },
        { value: 2, text: '關閉' },
        { value: true, text: '開啟' },
        { value: false, text: '關閉' },
      ],
      linkOptions: [
        { value: '_self', text: '本頁直接開啟' },
        { value: '_blank', text: '另開新視窗' },
      ],
      logNameOptions: [
        { key: "contentTypeId", name: "單位" },
        { key: "contentClassId", name: "分類" },
        { key: "contentTagId", name: "標籤" },
        { key: "businessTypeId", name: "單位" },
        { key: "businessOneId", name: "單位" },
        { key: "businessTwoId", name: "單位" },
        { key: "title", name: "標題" },
        { key: "description", name: "內容" },
        { key: "startDate", name: "上架時間" },
        { key: "endDate", name: "下架時間" },
        { key: "url", name: "網址" },
        { key: "linkMethod", name: "開啟方式" },
        { key: "deployStatus", name: "是否開放" },
        { key: "createDate", name: "建立日期" },
        { key: "modifyDate", name: "異動日期" },
        { key: "userName", name: "使用者名稱" },
        { key: "userAccount", name: "使用者帳號" },
        { key: "AG_ID", name: "群組代碼" },
        { key: "password3", name: "前三次密碼" },
        { key: "changePwdDate", name: "最後一次密碼日期" },
        { key: "email", name: "電子信箱" },
        { key: "openStatus", name: "是否開放" },
        { key: "comment", name: "備註" },
        { key: "failCount", name: "鎖住次數" },
        { key: "failDate", name: "鎖住日期" },
        { key: "AG_NAME", name: "群組名稱" },
        { key: "password", name: "登入密碼" },
        { key: "uuid", name: "加密頁代碼" },
      ],
      model: {
        title: "提示",
        show: false,
        text: null
      },
      apiBrowseLog: (data) => this.userRequest.post("Eshntu/BrowseLog", data),
      apiContentType: (data) => this.userRequest.post("Eshntu/ContentType", data),
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.getPermission('PJ202409010005');
      
      try {
          this.logList = [];
          let jsonReq = {
            TYPE: "LIST",
            browse_log_SELECT: {
              operationName: this.search.operationName,
              userName: this.search.userName,
              updateTime_S: this.search.startDate,
              updateTime_E: this.search.OverDate,
              pageIndex: this.pagination.page,
              pageSize: this.pagination.perPage,
            }
          };
          let resToken = await this.userRequest.post(
              "Eshntu/Token"
          ); 
          jsonReq.Token = resToken.data;
          let res = await this.userRequest.post(
              "Eshntu/BrowseLog",
              jsonReq
          );
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            //console.log(jsonData)
            this.logList = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
          }
      } catch (err) {
          console.log(err);
      }
    },
    datetimeFormat(value) {
      if (value) {
        let date = value.split("T")[0];
        date = date.replaceAll("-", "/");
        let time = value.split("T")[1];
        time = time.split(".")[0];
        return date + '  ' + time;
      } else {
        return null;
      }
    },
    getModelHide() {
      this.model.show = false;
      this.model.text = "";
    },
    getModelShow(model) {
      this.model.title = this.getType(model.parameterName, model.parameterId) + '(' + model.operationName + ')';
      this.model.text = "異動人員 - " + model.userName + "<br/><br/>";
      
      let jsonData = JSON.parse(model.description);
      if (jsonData.length > 0) {
        jsonData.forEach((x) => {
          let log = this.logNameOptions.find(d => d.key == x.name);
          let logName = (log==null)?"":log.name;
          if(x.name == "deployStatus" || x.name == "openStatus") {
            let befter = this.statusOptions.find(d => d.value == x.befter)
            x.befter = (befter==null)?"":befter.text;

            let after = this.statusOptions.find(d => d.value == x.after)
            x.after = (after==null)?"":after.text;
          } else if(x.name == "linkMethod") {
            let befter = this.linkOptions.find(d => d.value == x.after)
            x.befter = (befter==null)?"":befter.text;
            
            let after = this.linkOptions.find(d => d.value === x.after)
            x.after = (after==null)?"":after.text;
          } else if(x.name == "businessTypeId" || x.name == "contentTypeId") {
            x.befter = this.getType(model.parameterName, x.befter);
            x.after = this.getType(model.parameterName, x.after);
          }
          
          if(logName!==null && logName!="") {
            if(model.logType=="insert" || model.logType=="delete") {
              let log = this.logNameOptions.find(d => d.key === x.name);
              this.model.text += 
                logName + "-" + x.after + "<br>";
            } else if(model.logType=="update") {
              this.model.text += 
                logName + "-" + x.befter + "=>" + x.after + "<br>";
            } 
          }
        });
      } else { this.model.text += "無資料"; }

      this.model.show = true;
    },
    getType(Type, Id) {
      let typeName = "";

      if(Type == "business") {
        switch(Id)
        {
          case "1":
              typeName = "教育訓練";
              break;
          case "2":
              typeName = "健康管理"
              break;
          case "3":
              typeName = "保護計劃"
              break;
          case "4":
              typeName = "實驗場所"
              break;
          case "5":
              typeName = "廢棄物清運"
              break;
          case "6":
              typeName = "災害防救"
              break;
          case "7":
              typeName = "自動檢查"
              break;
          case "8":
              typeName = "其他業務"
              break;
          case "9":
              typeName = "環安衛宣導"
              break;
          case "10":
              typeName = "相關委員會"
              break;
          case "11":
              typeName = "中心簡介"
              break;
          case "12":
              typeName = "法規輯要"
              break;
          case "13":
              typeName = "文件下載"
              break;
          case "14":
              typeName = "駐警隊-關於本隊"
              break;
          case "15":
              typeName = "駐警隊-服務項目"
              break;
          case "16":
              typeName = "駐警隊-常用連結"
              break;
          case "17":
              typeName = "Training"
              break;
          case "18":
              typeName = "Health Management"
              break;
          case "19":
              typeName = "Laboratories"
              break;
          case "20":
              typeName = "About EHS"
              break;
          case "21":
              typeName = "Work Group"
              break;
        }
      } else if (Type == "content") {
        switch(Id)
        {
          case "1":
              typeName = "環安衛-訊息公告";
              break;
          case "2":
              typeName = "駐警隊-最新消息"
              break;
          case "3":
              typeName = "常見問題"
              break;
          case "4":
              typeName = "環安衛-訊息公告-英文版"
              break;
        }
      } else if (Type == "User_Info") {
          typeName = "使用者維護";
      } else if (Type == "Account_Group") {
          typeName = "群組維護";
      } else if (Type == "Account_Group_Permission") {
          typeName = "權限維護";
      } else if (Type == "Private_Content") {
          typeName = "加密頁面";
      } else if (Type == "Private_Content") {
          typeName = "加密頁面";
      }
      return typeName;
    },
  }
};
</script>