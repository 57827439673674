<template>

  <div>
     <Header :header="header"></Header>
     <SideBar :sider="sider"></SideBar>
     <!--主內文區塊-->
     <div class="inmcont">
          <SideLeft :left="left"></SideLeft>

          <div class="inmc_right">

            <div v-if="!detail">
              <!--單元內文介紹區-->
              <div class="conartical">
                  <div class="conal">
                      <div class="articl form-inline">
                        <label>查詢資訊：</label>
                        <b-form-input
                            class="w-50"
                            v-model="search.keyword"
                            type="text"
                            placeholder="請輸入欲查詢遺失物或遺失地點"
                            maxlength="80"
                          ></b-form-input
                        >
                        <b-button 
                          class="mx-2"
                          @click="GetContentSearch"
                          >開始查詢</b-button
                        >
                      </div>
                      <div class="articl">
                          <p>認領本隊受理之遺失物，請攜帶相關證件於上班時間（08時-17時）內，至本隊辦公室認領。</p>
                      </div>
                      
                      <div class="losttable">

                        <div class="nwstitle">
                            <div class="nwstb01">公告時間</div>
                            <div class="nwstb02">公告主旨</div>
                            <div class="nwstb03">公告單位</div>
                        </div>
                        <div class="nwscbar" v-if="loading">
                          <b-spinner label="Spinning"></b-spinner>
                        </div>
                        <div class="nwscbar" v-else-if="List.length == 0" >
                            <div class="nwstb01"></div>
                            <div class="nwstb02">
                              目前尚無資訊
                            </div>
                            <div class="nwstb03"></div>
                        </div>
                        <div v-else 
                            v-for="item in List" :key="item.loseId" 
                            class="nwscbar">
                            <a
                                :href="'#/Police/Lose?id=' + item.loseId"
                                title="在原視窗打開鏈結"
                              >
                                <div class="nwstb01">{{ dateFormat(item.publicDate) }}</div>
                                <div class="nwstb02">{{ item.subject }}</div>
                                <div class="nwstb03">{{ item.unit1 }} - {{ item.unit2 }}</div>
                            </a>
                        </div>
                    </div>
                    
                    <!--頁碼-->
                    <div class="twpage">
                      <div class="navigation">
                        <b-pagination
                          class="pagination light-theme simple-pagination"
                          v-model="pagination.page"
                          :total-rows="pagination.rows"
                          :per-page="pagination.perPage"
                          aria-controls="my-table"
                        ></b-pagination>
                      </div>
                    </div>
                      
                  </div>
              </div>
              <!--單元內文介紹區結束-->
            </div>

            <div v-else>
              <!--單元內文介紹區-->
              <div class="conartical">
                  <div class="conal">
                      <div class="articl">
                          <div class="nwsh1">{{ contentDetail.subject }}</div>
                          <div class="artcblk">
                            <p>認領本隊受理之遺失物，請攜帶相關證件於上班時間（08時-17時）內，至本隊辦公室認領。</p>
                            <table width="100%" border="0" cellspacing="0" cellpadding="0" class="lostintbe">
                              <tbody>
                                <tr>
                                  <td>公告主旨</td>
                                  <td>{{ contentDetail.subject }}</td>
                                </tr>
                                <tr>
                                  <td>公告單位</td>
                                  <td>{{ contentDetail.unit1 }} - {{ contentDetail.unit2 }}</td>
                                </tr>
                                <tr>
                                  <td>公告時間</td>
                                  <td>{{ contentDetail.publicTime }}</td>
                                </tr>
                                <tr>
                                  <td>公告內容</td>
                                  <td v-html="contentDetail.description" />
                                </tr>
                                <tr v-if="contentDetail.contactName!=''">
                                  <td>聯絡人</td>
                                  <td>{{ contentDetail.contactName }}</td>
                                </tr>
                                <tr v-if="contentDetail.contactTel!=''">
                                  <td>聯絡電話</td>
                                  <td>{{ contentDetail.contactTel }}</td>
                                </tr>
                                <tr v-if="contentDetail.contactMail!=''">
                                  <td>電子郵件</td>
                                  <td>{{ contentDetail.contactMail }}</td>
                                </tr>
                                <tr v-if="contentDetail.Partner!=''">
                                  <td>公告對象</td>
                                  <td>{{ contentDetail.Partner }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                      </div>
                      <div class="backbtn"><a href="#/Police/Lose">返回列表</a></div>
                  </div>
              </div>
              <!--單元內文介紹區結束--> 
            </div>
          </div>
      </div>
       <!--主內文區塊結束--> 

      <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../../components/default/Police/NavBar/Header.vue";
import SideLeft from "../../../components/default/Police/NavBar/SideLeft.vue";
import SideBar from "../../../components/default/Police/NavBar/SideBar.vue";
import Footer from "../../../components/default/Police/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      id: null,
      detail: false,
      loading: false,
      header: {
        text1: "駐警隊",
        text2: "失物招領",
      },
      sider: {
        index: 2,
        type: 0,
        list: [{ text: "失物招領", url: "" }],
      },
      left: {
        title: "失物招領",
        icon: "conmpc02.png",
        color: "txt_blu",
        list: [],
      },
      footer: {},
      pagination: {
        perPage: 10,
        page: 1,
        rows: 5,
      },
      search: {
        keyword: "",
      },
      List: [],
      contentDetail: {},
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    async init() {     
      window.scrollTo(0, 0);

      let id = this.$route.query.id;
      if (id == undefined) {   
        await this.GetContentSearch(this.tag, null);
      } else {
        this.detail = true;

        try {
          this.contentDetail = {};
          let jsonReq = {
            TYPE: "HOME_DETAIL",
            lose_SELECT: {
              loseId: id,
            },
          };
          let resToken = await this.userRequest.post(
            "Eshntu/Token"
          ); 
          jsonReq.Token = resToken.data;
          let res = await this.userRequest.post(
            "Eshntu/Lose",
            jsonReq
          );
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.contentDetail = jsonData;
            
            this.sider.list = [
                            { text: "失物招領", url: "#/Police/Lose" },
                            { text: this.contentDetail.subject, url: "" }
                          ];
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    async GetContentSearch(tag, classic) {
      this.detail = false;
      this.loading = true;
      
      try {
        this.List = [];
        let jsonReq = {
          TYPE: "HOME_LIST",
          lose_SELECT: {
            keywords: this.search.keyword,
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Lose",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            
            this.List = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
            this.loading = false;

            this.sider.list = [{ text: "失物招領", url: "" }];
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
