<template>
  <!--次次選單-->
  <div class="sssmnu" v-if="List.length > 1">
    <div v-for="(item,index) in List" :key="item.key"
        class="sssmbx">
        <a :href="item.url" :class="(id==null&&index==0)?'ssmac':(item.key==id?'ssmac':'')">{{ item.title }}</a>
    </div> 
  </div>
  <!--次次選單結束-->
</template>

<script>
export default {
  data() {
    return {
      List: [],
    };
  },
  props: ["left", "main", "type", "L1", "L2", "id"],
  mounted() {

  },
  watch: {
    "type"() {
      //this.init();
    },
    "L1"() {
      this.init();
    },
    "L2"() {
      //this.init();
    },
  },
  methods: {
    async init() {
      this.List = [];
      this.List.push(
        { key: this.L1, title: "illustrate", url: "#/EN/"+this.left.url+"?type="+this.type+"&L1="+this.L1+"&id="+this.L1 }
      );

      try {
        let jsonReq = {
          TYPE: "HOME_TWO_LEFT",
          business_SELECT: {
            businessTypeId: this.main,
            businessOneId: this.type,
            businessTwoId: this.L1,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Business",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
          let jsonData = JSON.parse(json.Data);
          if (jsonData.length > 0) {
            jsonData.forEach((x) => {
              let obj = { key: x.key, title: x.value, url: "#/EN/"+this.left.url+"?type="+this.type+"&L1="+x.L2+"&L2="+x.key+"&id="+x.key }
              this.List.push(obj);
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
