<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider" :option="mainOptions"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left" :main="5" :type="type" :L1="L1" :id="Id"></SideLeft>

        <div class="inmc_right">
            <div class="cntitle">
              <div>{{title}}</div>     
            </div> 
            <SideRight :left="left" :main="5" :type="type" :L1="L1" :L2="L2" :id="Id"></SideRight>
            
            <!--單元內文介紹區-->
            <b-overlay :show="loading" rounded="sm">
              <div class="conartical">
                  <div class="conal">
                      <div class="nwstpbr">
                          <div class="nwtbsstx">
                            共 {{Math.ceil(pagination.rows/pagination.perPage)}} 頁．{{pagination.rows}}筆資料
                          </div>
                      </div>
                      <div class="articl">
                          <div class="downall">
                            <div class="dwbar" v-if="loading">
                              <b-spinner label="Spinning"></b-spinner>
                            </div>
                            <div class="dwbar" v-else-if="List.length == 0" >
                                <div class="dwtitle"></div>
                                <div class="dwstyle">
                                  目前尚無資訊
                                </div>
                            </div>
                             <div class="dwbar" 
                                  v-else 
                                  v-for="item in List" :key="item.downloadId">
                                 <div class="dwtitle">{{ item.title }}</div>
                                 <div class="dwstyle">
                                     <div class="dwbtn2"
                                        v-for="file in item.outExFile" :key="file.filePath">
                                          <a :href="'/fileStore'+file.filePath" :title="file.fileName+'(另開新視窗)'" target="_blank">{{ file.fileExt.toUpperCase() }}</a></div>
                                 </div>
                             </div> 
                          </div>
                      </div>
                  </div>
              </div>
            </b-overlay>
            <!--單元內文介紹區結束-->
        </div>
    </div>  
    <!--主內文區塊結束-->  
      
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/default/NavBar/Header.vue";
import SideLeft from "../../components/default/NavBar/SideLeft.vue";
import SideRight from "../../components/default/NavBar/SideRight.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    SideRight,
    Header,
    Footer,
  },
  data() {
    return {
      loading: false,
      type: null,
      L1: null,
      L2: null,
      Id: null,
      title: "環安衛行事曆",
      header: {
        text1: "文件下載",
        text2: null,
      },
      sider: {},
      left: {},
      footer: {},
      pagination: {
        perPage: 30,
        page: 1,
        rows: 5,
      },
      List: [],
      mainOptions: [],
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
    "pagination.page"() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.type = this.$route.query.type;
      this.L1 = this.$route.query.L1; 
      this.L2 = this.$route.query.L2; 
      this.sider = {
          index: 5,
          type: this.type,
          list: [{ text: "文件下載", url: "" }],
      };

      this.left = {
          id: 5,
          url: "Download",
          title: "文件下載",
          icon: "mnu05.svg",
          color: "bgc_pink",
          list: [
            { key:0, title: "環安衛行事曆", url: "#/Schedule?type="+this.type+"&L1=0&id=0"}
          ],
        };

      window.scrollTo(0, 0);
      this.loading = true;

      try {
        this.List = [];
        let jsonReq = {
          TYPE: "HOME_LIST",
          download_SELECT: {
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
            downloadTypeId: 2,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Download",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            
            this.List = jsonData.outData;
            this.pagination.rows = jsonData.totalCount;
            this.loading = false;
        }
      } catch (err) {
        console.log(err);
      } 
    },
  },
};
</script>
