<template>
  <div>
    <div v-if="tvShow">
      <div class="tvtitle"><img src="/images/TVtitle.png"  alt=""/></div>

      <div class="tvelecall">
          <div class="elecall">
                <div class="elemnum">總用電<span class="elh1">{{ power.total }}</span>度</div>
                <div class="elebuldec">
                    <img src="/images/building.png" alt=""/>

                    <!--永續廳用電-->
                    <div class="elecnear">
                        <div class="elnrnm">永續廳</div> 
                        <div class="elnreal">冷氣總用電<br><span>{{ power.L1Froom }}</span>度</div>
                    </div>
                    <!--永續廳用電結束-->

                    <!--各樓層用電量-->
                    <div class="eleflnumall">
                        <div class="elefnm5F">
                            <div class="elfnm"><span>5</span>F</div>
                            <div class="elalnbox">
                              <div class="alelc">一般總用電 <span>{{ power.L5Ftotal }}</span> 度</div>
                              <div class="alair">冷氣總用電 <span>{{ power.L5Fcold }}</span>度</div>
                            </div>
                        </div>
                        <div class="elefnm4F">
                            <div class="elfnm"><span>4</span>F</div>
                            <div class="elalnbox">
                              <div class="alelc">一般總用電 <span>{{ power.L4Ftotal }}</span> 度</div>
                              <div class="alair">冷氣總用電 <span>{{ power.L4Fcold }}</span>度</div>
                            </div>
                        </div>
                        <div class="elefnm3F">
                            <div class="elfnm"><span>3</span>F</div>
                            <div class="elalnbox">
                              <div class="alelc">一般總用電 <span>{{ power.L3Ftotal }}</span> 度</div>
                              <div class="alair">冷氣總用電 <span>{{ power.L3Fcold }}</span>度</div>
                            </div>
                        </div>
                        <div class="elefnm2F">
                            <div class="elfnm"><span>2</span>F</div>
                            <div class="elalnbox">
                              <div class="alelc">一般總用電 <span>{{ power.L2Ftotal }}</span> 度</div>
                              <div class="alair">冷氣總用電 <span>{{ power.L2Fcold }}</span>度</div>
                            </div>
                        </div>
                        <div class="elefnm1F">
                            <div class="elfnm"><span>1</span>F</div>
                            <div class="elalnbox">
                              <div class="alelc">一般總用電 <span>{{ power.L1Ftotal }}</span> 度</div>
                              <div class="alair">冷氣總用電 <span>{{ power.L1Fcold }}</span>度</div>
                            </div>
                        </div>
                        <div class="elefnmB1F">
                            <div class="elfnm"><span>B1</span>F</div>
                            <div class="elalnbox">
                              <div class="alelc">一般總用電 <span>{{ power.B1Ftotal }}</span> 度</div>
                              <div class="alair">冷氣總用電 <span>{{ power.B1Fcold }}</span>度</div>
                            </div>
                        </div>
                        <div class="elefnmB2F">
                            <div class="elfnm"><span>B2</span>F</div>
                            <div class="elalnbox">
                              <div class="alelc">一般總用電 <span>{{ power.B2Ftotal }}</span> 度</div>
                            </div>
                        </div>
                    </div>
                    <!--各樓層用電量結束-->
                </div>
            </div>
      </div>

    </div>
    <div v-else>
      <Header :header="header"></Header>
      <SideBar :sider="sider" :option="mainOptions"></SideBar>

      <!--主內文區塊-->  
      <div class="inmcont">
          <SideLeft :left="left" :main="3" :type="11" :L1="99" :Id="99"></SideLeft>

          <div class="inmc_right">
              <!--單元內文介紹區-->
              <div class="conartical">
                  <div class="elecall">
                      <div class="elemnum">
                        <span class="elm">環境研究大樓</span>總用電
                        <span class="elh1">{{ power.total }}</span>度</div>
                      <div class="elebuldec">
                          <img src="/images/building.png" alt=""/>
                          
                          <!--永續廳用電-->
                          <div class="elecnear">
                              <div class="elnrnm">永續廳</div> 
                              <div class="elnreal">冷氣總用電<br><span>{{ power.L1Froom }}</span>度</div>
                          </div>
                          <!--永續廳用電結束-->
                          
                          <!--各樓層用電量-->
                          <div class="eleflnumall">
                              <div class="elefnm5F">
                                  <div class="elfnm"><span>5</span>F</div>
                                  <div class="elalnbox">
                                      <div class="alelc">一般總用電 <span>{{ power.L5Ftotal }}</span> 度</div>
                                      <div class="alair">冷氣總用電 <span>{{ power.L5Fcold }}</span>度</div>
                                  </div>
                              </div>
                              <div class="elefnm4F">
                                  <div class="elfnm"><span>4</span>F</div>
                                  <div class="elalnbox">
                                      <div class="alelc">一般總用電 <span>{{ power.L4Ftotal }}</span> 度</div>
                                      <div class="alair">冷氣總用電 <span>{{ power.L4Fcold }}</span>度</div>
                                  </div>
                              </div>
                              <div class="elefnm3F">
                                  <div class="elfnm"><span>3</span>F</div>
                                  <div class="elalnbox">
                                      <div class="alelc">一般總用電 <span>{{ power.L3Ftotal }}</span> 度</div>
                                      <div class="alair">冷氣總用電 <span>{{ power.L3Fcold }}</span>度</div>
                                  </div>
                              </div>
                              <div class="elefnm2F">
                                  <div class="elfnm"><span>2</span>F</div>
                                  <div class="elalnbox">
                                      <div class="alelc">一般總用電 <span>{{ power.L2Ftotal }}</span> 度</div>
                                      <div class="alair">冷氣總用電 <span>{{ power.L2Fcold }}</span>度</div>
                                  </div>
                              </div>
                              <div class="elefnm1F">
                                  <div class="elfnm"><span>1</span>F</div>
                                  <div class="elalnbox">
                                      <div class="alelc">一般總用電 <span>{{ power.L1Ftotal }}</span> 度</div>
                                      <div class="alair">冷氣總用電 <span>{{ power.L1Fcold }}</span>度</div>
                                  </div>
                              </div>
                              <div class="elefnmB1F">
                                  <div class="elfnm"><span>B1</span>F</div>
                                  <div class="elalnbox">
                                      <div class="alelc">一般總用電 <span>{{ power.B1Ftotal }}</span> 度</div>
                                      <div class="alair">冷氣總用電 <span>{{ power.B1Fcold }}</span>度</div>
                                  </div>
                              </div>
                              <div class="elefnmB2F">
                                  <div class="elfnm"><span>B2</span>F</div>
                                  <div class="elalnbox">
                                      <div class="alelc">一般總用電 <span>{{ power.B2Ftotal }}</span> 度</div>
                                  </div>
                              </div>
                          </div>
                          <!--各樓層用電量結束-->
                      </div>
                      
                  </div>  
                        
              </div>
              <!--單元內文介紹區結束-->
          </div>
      </div>  
      <!--主內文區塊結束-->  
        
      <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
      
      <Footer :footer="footer"></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../../components/default/NavBar/Header.vue";
import SideLeft from "../../components/default/NavBar/SideLeft.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    Header,
    Footer,
  },
  data() {
    return {
      type: 1,
      tvShow: false,
      tag: 99,
      header: {
        text1: "中心簡介",
        text2: null,
      },  
      sider: {
          index: 3,
          type: 1,
          list: [{ text: "數位電表", url: "" }],
      },
      left: {
        id: 3,
        url: "About",
        title: "中心簡介",
        icon: "mnu03.svg",
        color: "bgc_blue",
        list: [ 
          { key:99, title: "數位電表", url: "#/Power"},
        ],
      },
      footer: {},
      power: {
        B2Ftotal: 0,
        B1Ftotal: 0,
        B1Fcold: 0,
        L1Ftotal: 0,
        L1Fcold: 0,
        L2Ftotal: 0,
        L2Fcold: 0,
        L3Ftotal: 0,
        L3Fcold: 0,
        L4Ftotal: 0,
        L4Fcold: 0,
        L5Ftotal: 0,
        L5Fcold: 0,
        L1Froom:0,
        total: 0,
      },
      pagination: {
        perPage: 99,
        page: 1,
        rows: 5,
      },
      mainOptions: [],
      apiPower: (data) =>
        this.userRequest.post("Eshntu/", data),
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.type = this.$route.query.type;
      if(this.type == 2) {
        this.tvShow = true;
        document.body.classList.remove('inpgcn')
        document.body.classList.add('tvelec');
      }
      window.scrollTo(0, 0);

      try {
        let jsonReq = {
          TYPE: "LIST",
          power_SELECT: {
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Power",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.contentList = jsonData.outData;
            let tB2Ftotal = 0;
            let tB1Ftotal = 0;
            let tB1Fcold = 0;
            let tL1Ftotal = 0;
            let tL1Fcold = 0;
            let tL2Ftotal = 0;
            let tL2Fcold = 0;
            let tL3Ftotal = 0;
            let tL3Fcold = 0;
            let tL4Ftotal = 0;
            let tL4Fcold = 0;
            let tL5Ftotal = 0;
            let tL5Fcold = 0;
            let tL1Froom = 0;
            let ttotal = 0;
            jsonData.outData.forEach((x) => {
              if(x.building_id=="B2F-1") tB2Ftotal = x.kwh;
              if(x.building_id=="B1F-1") tB1Ftotal = x.kwh;
              if(x.building_id=="B1F-2") tB1Fcold = x.kwh;
              if(x.building_id=="1F-1") tL1Ftotal = x.kwh;
              if(x.building_id=="1F-2") tL1Fcold = x.kwh;
              if(x.building_id=="1F-3") tL1Froom = x.kwh;
              if(x.building_id=="2F-1") tL2Ftotal = x.kwh;
              if(x.building_id=="2F-2") tL2Fcold = x.kwh;
              if(x.building_id=="3F-1") tL3Ftotal = x.kwh;
              if(x.building_id=="3F-2") tL3Fcold = x.kwh;
              if(x.building_id=="4F-1") tL4Ftotal = x.kwh;
              if(x.building_id=="4F-2") tL4Fcold = x.kwh;
              if(x.building_id=="5F-1") tL5Ftotal = x.kwh;
              if(x.building_id=="5F-2") tL5Fcold = x.kwh;
              if(x.building_id=="TOTAL") ttotal = x.kwh;
            });
            this.power = {
              B2Ftotal: tB2Ftotal,
              B1Ftotal: tB1Ftotal,
              B1Fcold: tB1Fcold,
              L1Ftotal: tL1Ftotal,
              L1Fcold: tL1Fcold,
              L2Ftotal: tL2Ftotal,
              L2Fcold: tL2Fcold,
              L3Ftotal: tL3Ftotal,
              L3Fcold: tL3Fcold,
              L4Ftotal: tL4Ftotal,
              L4Fcold: tL4Fcold,
              L5Ftotal: tL5Ftotal,
              L5Fcold: tL5Fcold,
              L1Froom: tL1Froom,
              total: ttotal,
            }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
