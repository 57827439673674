<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider" :option="mainOptions"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left" :main="1" :type="type" :L1="L1" :id="Id" :option="mainOptions"></SideLeft>

        <div class="inmc_right">
            <div class="cntitle">
              <div>{{contentDetail.businessTwoName??contentDetail.title}}</div>     
            </div> 
            <SideRight :left="left" :main="1" :type="type" :L1="L1" :L2="L2" :id="Id"></SideRight>
            
            <!--單元內文介紹區-->
            <b-overlay :show="loading" rounded="sm">
              <div class="conartical">
                  <div class="update">最後更新：{{ dateFormat(contentDetail.modifyDate) }}</div>
                  <div class="articl" v-html="contentDetail.description"></div>
              </div>
            </b-overlay>
            <!--單元內文介紹區結束-->
        </div>
    </div>  
    <!--主內文區塊結束-->  
      
    <div class="illpc01"><img src="/images/illpc01.png" alt=""/></div>
     
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../components/default/NavBar/Header.vue";
import SideLeft from "../../components/default/NavBar/SideLeft.vue";
import SideRight from "../../components/default/NavBar/SideRight.vue";
import SideBar from "../../components/default/NavBar/SideBar.vue";
import Footer from "../../components/default/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    SideRight,
    Header,
    Footer,
  },
  data() {
    return {
      loading: false,
      type: null,
      L1: null,
      L2: null,
      Id: null,
      header: {
        text1: "主要業務",
        text2: null,
      },
      sider: {},
      left: {},
      footer: {},
      contentDetail: {},
      mainOptions: [
        { value: 1, text: "教育訓練" },
        { value: 2, text: "健康管理" },
        { value: 3, text: "保護計畫" },
        { value: 4, text: "實驗場所" },
        { value: 5, text: "廢棄物清運" },
        { value: 6, text: "災害防救" },
        { value: 7, text: "自動檢查" },
        { value: 9, text: "環安衛宣導" },
        { value: 10, text: "相關委員會" },
        { value: 8, text: "其它業務" },
      ],
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.type = this.$route.query.type;
      this.L1 = this.$route.query.L1; 
      this.L2 = this.$route.query.L2; 
      this.sider = {
          index: 1,
          type: this.type,
          list: [{ text: "主要業務", url: "" }],
      };

      let title = this.mainOptions.find(x => x.value == this.type).text;
      this.left = {
        id: 1,
        url: "Content",
        title: title,
        icon: "msv"+this.type.padStart(2, 0)+".svg",
        color: "bgc_pink",
        list: [],
      };
      
      this.Id = this.$route.query.id;
      if (this.Id == undefined) {  
        window.scrollTo(0, 0);

        try {
            this.contentDetail = {};
            let jsonReq = {
              TYPE: "HOME_FIRST_DETAIL",
              business_SELECT: {
                businessTypeId: 1,
                businessOneId: this.type,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Business",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
                let jsonData = JSON.parse(json.Data);
                this.contentDetail = jsonData;
                this.L1 = this.contentDetail.businessOneId;
                this.Id = this.contentDetail.businessId; 
                
                if(this.contentDetail.url!=null) {
                  if(this.contentDetail.linkMethod == "_self") {
                    window.location.href = "#"+this.contentDetail.url.replace("/#/","").replace("#/","");
                  } else {
                    window.open(this.contentDetail.url);
                    this.$router.go(-1);
                  }
                }

                this._go(this.$route.path+"?type="+this.type+"&L1="+this.Id+"&id="+this.Id);
            }
        } catch (err) {
            console.log(err);
        }     
      } else {
        this.loading = true;
        
        try {
            this.contentDetail = {};
            let jsonReq = {
              TYPE: "HOME_DETAIL",
              business_SELECT: {
                businessId: this.Id,
                businessTypeId: 1,
              },
            };
            let resToken = await this.userRequest.post(
                "Eshntu/Token"
            ); 
            jsonReq.Token = resToken.data;
            let res = await this.userRequest.post(
                "Eshntu/Business",
                jsonReq
            );
            let json = JSON.parse(res.data);
            if (json.Status) {
              let jsonData = JSON.parse(json.Data);
              this.contentDetail = jsonData;
              
              if(this.contentDetail.url!=null) {
                if(this.contentDetail.linkMethod == "_self") {
                  window.location.href = "#"+this.contentDetail.url.replace("/#/","").replace("#/","");
                } else {
                  window.open(this.contentDetail.url);
                  this.$router.go(-1);
                }
              }

              this.loading = false;
            }
        } catch (err) {
            console.log(err);
        }
      }
    },
  },
};
</script>
