<template>
  <div style="width: 100%">
    <editor 
      v-model="content" 
      :init="init"
      :disabled="disabled"></editor>
  </div>
</template>

<script>
import axios from "axios";
import tinymce from "tinymce/tinymce";
import editor from "@tinymce/tinymce-vue";
import "tinymce/models/dom";

// 外觀
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/themes/silver";

// Icon
import "tinymce/icons/default";

// 語言包
import 'tinymce-i18n/langs5/zh_TW.js';

import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/preview";

export default {
  name: "tinymce-editor",
  components: {
    editor,
  }, 
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default: "lists image media table wordcount lineheight link preview code charmap",
    },
    toolbar: {
      type: [String, Array],
      default:
        "undo redo | styles | formatselect | fontfamily | fontsize | lineheight | charmap | bold italic forecolor backcolor | bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | table lists image media link | removeformat preview code responsivefilemanager ",
    },
  },
  data() {
    return {
      init: {
        base_url: "/tinymce/",
        //language_url: "tinymce/langs/zh_TW.js",
        language: "zh_TW",
        skin_url: "tinymce/skins/ui/oxide",
        height: 500,
        skin: false,
        branding: false,
        menubar: false,
        plugins: this.plugins,
        toolbar: this.toolbar,
        content_style: "p {margin: 20px 0;}",
        content_css: "/css/editor.css?" + new Date().getTime(),
        font_size_formats: "0.8em 1em 1.2em 1.5em 2em 2.5em 3em",
        font_family_formats: "微軟正黑體='微軟正黑體';Microsoft JhengHei;新細明體='新細明體';PMingLiU;標楷體='標楷體';DFKai-sb;Andale Mono='andale mono',monospace;Arial='arial',helvetica,sans-serif;Arial Black='arial black',sans-serif;Book Antiqua='book antiqua',palatino,serif;Comic Sans MS='comic sans ms',sans-serif;Courier New='courier new',courier,monospace;Georgia='georgia',palatino,serif;Helvetica='helvetica',arial,sans-serif;Impact='impact',sans-serif;Terminal='terminal',monaco,monospace;Trebuchet MS='trebuchet ms',geneva,sans-serif;Verdana='verdana',geneva,sans-serif",
        lineheight_formats : '1 1.5 2',
        paste_auto_cleanup_on_paste: true, 
        paste_remove_styles: true, 
        paste_remove_styles_if_webkit: true, 
        paste_strip_class_attributes: 'all',
        paste_retain_style_properties: "all",
        paste_as_text: false, 
        paste_data_images: true,
        formats: {
          artable: { selector: 'table', classes: 'artable' },
          ath1: { block: 'div', classes: 'ath1' },
          lawtitb: { block: 'div', classes: 'lawtitb' },
          dwbtn: { selector: 'a', classes: 'dwbtn' },
          dwbtn1: { selector: 'a', classes: 'dwbtn1' },
          lostintbe: { selector: 'table', classes: 'lostintbe' },
          rulab: { selector : 'ul', classes: 'rulab'  },
          linlal: { selector : 'ul', classes: 'linlal'  },
          imptarea: { block : 'p', classes: 'imptarea'  },
        },
        style_formats: [
          { title: '表頭黑底-表格', format: 'artable' },
          { title: '白黃表身-表格', format: 'lostintbe' },
          { title: '左側紅標紅字', format: 'ath1' },
          { title: '左側綠點綠字', format: 'lawtitb' },
          { title: '黃底按鈕', format: 'imptarea' },
          { title: '下載藍色按鈕(無圖示)', format: 'dwbtn' },
          { title: '下載藍色按鈕(有圖示)', format: 'dwbtn1' },
          { title: '下載超連結表列(有PDF圖示)', format: 'rulab' },
          { title: '下載超連結表列(有連結圖示)', format: 'linlal' },
        ],
        image_title: false,
        //images_upload_url: "/d_upload_sys/",
        relative_urls: false,
        /*images_upload_handler: (blobInfo, progress) => new Promise ((resolve, reject) => {
          const uploadingElement = document.getElementById("uploading");
          uploadingElement.classList.remove("dispnone");

          var xhr, formData;
          var file = blobInfo.blob();
          xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open('POST', '/api/Eshntu/UPFile3jpg');
          xhr.onload = function() {
              const uploadingElement = document.getElementById("uploading");
              uploadingElement.classList.add("dispnone");

              var json = JSON.parse(xhr.responseText);
              if(!json.includes("/d_upload_sys/")) {
                reject(json, {remove: true});  
              } else 
                resolve(json);
          };
          formData = new FormData();
          formData.append('httpPostedFile', file, file.name );
          xhr.send(formData);
          xhr.onerror = function() {
              const uploadingElement = document.getElementById("uploading");
              uploadingElement.classList.add("dispnone");

              reject('由於XHR傳輸錯誤，圖像上傳失敗。代碼:' + xhr.status)
          }
          xhr.upload.onprogress = function (e) {
              progress(e.loaded / e.total * 100);
          };
        }),*/
        file_picker_types: 'file media image',
        file_picker_callback: (callback, value, meta) => {
            var filetype = ".pdf, .zip, .docx, .doc, .xls, .xlsx, .odt, .ods, .odf, .mp4, .mp3, .jpg, .gif, .png";
            switch(meta.filetype) {
              case 'image':
                filetype = '.jpg, .png, .gif';
                break;
              case 'media':
                filetype = ".mp4, .mp3";
                break;
            }
            
            const node = document.createElement("div");
            
            const closenode = document.createElement("img");
            closenode.src = "/images/close.png";
            closenode.className = "iframe-close";
            closenode.onclick = function() {
              document.body.removeChild(node);
            }
            node.appendChild(closenode);

            const framenode = document.createElement("iframe");
            framenode.src = "fileManagerSys";
            framenode.id = "framenode";
            framenode.className = "iframe";
            node.appendChild(framenode);
            document.body.appendChild(node);

            window.addEventListener("message", (event) => {
              const fileUrl = event.data;
              //console.log(fileUrl);
              callback(fileUrl["message"]);
              document.body.removeChild(node);
              window.removeEventListener("message");
            })

            /*var uploadInput = document.createElement("input");
            uploadInput.setAttribute('type', 'file');
            uploadInput.setAttribute('accept', filetype);
            uploadInput.click();
            uploadInput.onchange = function () {
              const uploadingElement = document.getElementById("uploading");
              uploadingElement.classList.remove("dispnone");

              var file = this.files[0];
              var xhr, formData;
              xhr = new XMLHttpRequest();
              xhr.withCredentials = false;
              xhr.open('POST', '/api/Eshntu/UPFile3');
              xhr.onload = function () {
                tinymce.activeEditor.setProgressState(false);
                const uploadingElement = document.getElementById("uploading");
                uploadingElement.classList.add("dispnone");

                if(xhr.status!=200)
                {
                  alert("上傳大小過大，無法上傳成功；XHR傳輸錯誤，圖像上傳失敗。");
                  return;  
                }
                var json = JSON.parse(xhr.responseText);
                if (meta.filetype == 'image') {
                  if(!json.includes("/d_upload/")) {
                    alert(json);  
                  } else 
                    callback(json, { alt: file.name });
                } else {
                  if(!json.includes("/d_upload/")) {
                    alert(json);  
                  } else 
                    callback(json, { text: file.name, title: file.name + '(另開新視窗)' });
                }
              }
              formData = new FormData();
              formData.append('httpPostedFile', file, file.name );
              xhr.send(formData);
              xhr.onerror = function() {
                  tinymce.activeEditor.setProgressState(false);
                  const uploadingElement = document.getElementById("uploading");
                  uploadingElement.classList.add("dispnone");

                  alert('上傳大小過大，無法上傳成功；XHR傳輸錯誤，圖像上傳失敗。');
                  return;
              }
              xhr.upload.onprogress = function (e) {
                  let percent = (e.loaded / e.total) * 100;
                  if (percent < 100) {
                      tinymce.activeEditor.setProgressState(true);
                  } else {
                      tinymce.activeEditor.setProgressState(false);
                  }
              };
            }*/
        },
      },
      content: this.value,
    };
  },
  mounted() {
    tinymce.init({});
  },
  methods: {},
  watch: {
    value(newValue) {
      this.content = newValue;
    },
    content(newValue) {
      this.$emit("input", newValue);
    },
  },
};

function getPasteOption() {
  return {
    paste_preprocess: (editor, args) => {
      console.log(args.content);
    },
    paste_webkit_styles: "color",  
    paste_auto_cleanup_on_paste: true, //自動清理貼上內容
    paste_remove_styles: true, //移除貼上內容的樣式
    paste_remove_styles_if_webkit: true, //如果是WebKit瀏覽器,也移除樣式
    paste_strip_class_attributes: 'all', //移除所有class屬性
    paste_retain_style_properties: "all",
    paste_as_text: false, //以純文字形式貼上
  };
}
</script>