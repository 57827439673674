<template>
  <div>
    <Header :header="header"></Header>
    
    <div class="homdwcnal">
        <div class="hmdwc">
        
            <!--失物招領區塊-->
            <div class="nwbk">
                <div class="nwbin">
                    <div class="nwi_left">
                        <div class="nwtit">失物招領</div>
                        <div class="nwmore">
                            <a href="#/Police/Lose">
                                <div class="nwmrtxt">了解更多</div>
                                <div class="nwarow"></div>
                            </a>
                        </div>
                    </div>
                    <div class="nwi_right">
                        <div class="nwlistal">
                            <div v-for="item in List" :key="item.loseId" 
                              class="nwlisbar">
                                <a
                                    :href="'#/Police/Lose?id=' + item.loseId"
                                    title="在原視窗打開鏈結"
                                  >
                                    <div class="nwlsdate">{{ dateFormat(item.publicDate) }}</div>
                                    <div class="nwlswod">{{ item.subject }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nwill01"></div>
            </div>
            <!--失物招領區塊結束-->
            
            <!--最新消息區塊-->
            <div class="nwbk">
                <div class="nwbin">
                    <div class="nwi_left">
                        <div class="nwtit">最新消息</div>
                        <div class="nwmore">
                            <a href="#/Police/News">
                                <div class="nwmrtxt">了解更多</div>
                                <div class="nwarow"></div>
                            </a>
                        </div>
                    </div>
                    <div class="nwi_right">
                        <div class="nwlistal">
                            <div class="nwlisbar" v-for="item in newsList" :key="item.startDate">
                                <a :href="'#/Police/News/?id=' + item.contentId" :title="item.title">
                                    <div class="nwlsdate">{{ dateFormat(item.createDate) }}</div>
                                    <div class="nwlswod">{{ item.title }}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="nwill02"></div>
            </div>
            <!--最新消息區塊結束-->
        </div>
    </div>

    <div class="hommap"><img src="/images/police/map.png" alt=""/></div>

    <Footer :footer="footer"></Footer>
  </div>
</template>
<script>
import Header from "../../../components/default/Police/NavBar/Header_Index.vue";
import Footer from "../../../components/default/Police/NavBar/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      header: {
        text1: "",
        text2: "",
      },
      sider: {
        index: 0,
        type: 0,
        list: [],
      },
      footer: { 
        index: true, 
      },
      pagination: {
        perPage: 5,
        page: 1,
      },
      newsList: [],
      List: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.List = [];
        let jsonReq = {
          TYPE: "HOME_LIST",
          lose_SELECT: {
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Lose",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.List = jsonData.outData;
        }
      } catch (err) {
        console.log(err);
      }

      try {
        this.newsList = [];
        let jsonReq = {
          TYPE: "HOME_LIST",
          content_SELECT: {
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
            contentTypeId: 2,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Content",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.newsList = jsonData.outData;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};

</script>
