<template>

  <div>
    <Header :header="header"></Header>
    <SideBar :sider="sider"></SideBar>

    <!--主內文區塊-->  
    <div class="inmcont">
        <SideLeft :left="left" :main="main" :type="type" :L1="L1" :id="Id"></SideLeft>

        <div class="inmc_right">
            <!--單元內文介紹區-->
            <b-overlay :show="loading" rounded="sm">
              <div class="conartical">
                <div class="conal">
                  <div class="cntitle">
                    <div>{{contentDetail.businessTwoName??contentDetail.title}}</div>    
                  </div> 
                  <SideRight :left="left" :main="main" :type="type" :L1="L1" :L2="L2" :id="Id"></SideRight>
                  <div class="update">最後更新：{{ dateFormat(contentDetail.modifyDate) }}</div>
                  <div class="articl" v-html="contentDetail.description"></div>
                </div>
              </div>
            </b-overlay>
            <!--單元內文介紹區結束-->
        </div>
    </div>  
    <!--主內文區塊結束-->  
      
    <Footer :footer="footer"></Footer>
  </div>
</template>

<script>
import Header from "../../../components/default/Police/NavBar/Header.vue";
import SideLeft from "../../../components/default/Police/NavBar/SideLeft.vue";
import SideRight from "../../../components/default/Police/NavBar/SideRight.vue";
import SideBar from "../../../components/default/Police/NavBar/SideBar.vue";
import Footer from "../../../components/default/Police/NavBar/Footer.vue";
export default {
  components: {
    SideBar,
    SideLeft,
    SideRight,
    Header,
    Footer,
  },
  data() {
    return {
      loading: false,
      type: null,
      main: null,
      L1: null,
      L2: null,
      Id: null,
      header: {
        text1: "駐警隊",
        text2: "關於本隊",
      },
      sider: {},
      left: {},
      footer: {},
      contentDetail: {},
    };
  },
  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    "$route.query"() {
      this.init();
    },
  },
  methods: {
    async init() {
      this.type = this.$route.query.type;
      this.L1 = this.$route.query.L1; 
      this.L2 = this.$route.query.L2; 

      switch (parseInt(this.type)) {
        case 14: 
          this.main = 6; 
          this.sider = {
            index: 3,
            type: 2,
            list: [{ text: "關於本隊", url: "" }],
          };
          this.left = {
            id: 2,
            url: "Content",
            title: "關於本隊",
            icon: "conmpc03.png",
            color: "txt_org",
            list: [],
          };
          break;
        case 15:   
          this.main = 7; 
          this.sider = {
            index: 4,
            type: 2,
            list: [{ text: "服務項目", url: "" }],
          };
          this.left = {
            id: 2,
            url: "Content",
            title: "服務項目",
            icon: "conmpc04.png",
            color: "txt_green",
            list: [],
          };
          break;
        case 16:   
          this.main = 8; 
          this.sider = {
            index: 5,
            type: 2,
            list: [{ text: "常用連結", url: "" }],
          };
          this.left = {
            id: 2,
            url: "Content",
            title: "常用連結",
            icon: "conmpc05.png",
            color: "txt_blu",
            list: [],
          };
          break;
      }
      
      this.Id = this.$route.query.id;
      if (this.Id == undefined) {  
        window.scrollTo(0, 0);

        try {
          this.contentDetail = {};
          let jsonReq = {
            TYPE: "HOME_FIRST_DETAIL",
            business_SELECT: {
              businessTypeId: this.main,
              businessOneId: this.type,
            },
          };
          let resToken = await this.userRequest.post(
            "Eshntu/Token"
          ); 
          jsonReq.Token = resToken.data;
          let res = await this.userRequest.post(
            "Eshntu/Business",
            jsonReq
          );
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.contentDetail = jsonData;
            this.L1 = this.contentDetail.businessOneId;
            this.Id = this.contentDetail.businessId; 
            
            if(this.contentDetail.url!=null) {
              if(this.contentDetail.linkMethod == "_self") {
                window.location.href = "#"+this.contentDetail.url.replace("/#/","").replace("#/","");
              } else {
                window.open(this.contentDetail.url);
                this.$router.go(-1);
              }
            }
            
            this._go(this.$route.path+"?type="+this.type+"&L1="+this.Id+"&id="+this.Id);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.loading = true;

        try {
          this.contentDetail = {};
          let jsonReq = {
            TYPE: "HOME_DETAIL",
            business_SELECT: {
              businessId: this.Id,
              businessTypeId: this.main,
            },
          };
          let resToken = await this.userRequest.post(
            "Eshntu/Token"
          ); 
          jsonReq.Token = resToken.data;
          let res = await this.userRequest.post(
            "Eshntu/Business",
            jsonReq
          );
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.contentDetail = jsonData;
            
            if(this.contentDetail.url!=null) {
              if(this.contentDetail.linkMethod == "_self") {
                window.location.href = "#"+this.contentDetail.url.replace("/#/","").replace("#/","");
              } else {
                window.open(this.contentDetail.url);
                this.$router.go(-1);
              }
            }

            this.loading = false;
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
