<template>
  <div>
    <div v-if="sider.index==0">
      <div class="topbar">
          <div class="tpbrbox01"></div>
          <div class="tpbrbox02"></div>
          <div class="tpbrbox03"></div>
          <div class="tpbrbox04"></div>
          <div class="tpbrbox05"></div>
          <div class="tpbrbox06"></div>
      </div>
      <div class="tpqlnk">
          <span><a href="#/EN/Sitemap">Sitemap</a></span>｜<span><a href="https://www.ntu.edu.tw/english/" target="_blank" title="National Taiwan University(open a new window)">National Taiwan University</a></span>       
      </div>
      
      <nav class="menu">
          <ul>
              <li class="menu01"><a href="#/EN/Content?type=17">Main</a></li>
              <li class="menu02" v-if="newsList.length > 0"><a href="#/EN/News">Latest News</a></li>
              <li class="menu03"><a href="#/EN/About?type=20">About EHS</a></li>
              <li class="menu04"><a href="#/EN/Workgroup?type=21">Work Group</a></li>
          </ul>
          <div class="tpsearch">
              <div class="sertxb" v-if="isSearchIndex==true">
                <label for="keywordAll" style="display: none"> Keyword </label>
                <b-form-input
                    id="keywordAll"
                    v-model="searchKeyWordsIndex"
                    placeholder="Please input your keyword here"
                    v-on:keyup.enter="searchIndex"
                    class="setx"></b-form-input>
              </div>
              <div class="sech">
                <a href="javascript:" 
                    @click="searchIndex()" 
                    title="Start" 
                    role="button">&nbsp;</a></div>
          </div>

          <div class="language">
              <div class="lan_zh"><a href="#/">中</a></div>
              <div class="lan_en"><a href="#/EN" class="lanac">EN</a></div>
          </div>
      </nav>
      
      <!--========== 手機選單 ==========-->
      <div class="tright">
        <div id="trigger-overlay" class="in-burger"><span></span></div>
      </div>

      <div class="overlay overlay-hugeinc RWD_mnuopen">
          <button type="button" class="overlay-close">Close</button>
          <nav>
              <nav>
                  <ul>
                    <li><a href="#/EN/Content?type=17">Main</a></li>
                    <li v-if="newsList.length > 0"><a href="#/EN/News">Latest News</a></li>
                    <li><a href="#/EN/About?type=20">About EHS</a></li>
                    <li><a href="#/EN/Workgroup?type=21">Work Group</a></li>
                    <li class="RWD_lang">
                        <div class="language">
                            <div class="lan_zh"><a href="#/">中</a></div>
                            <div class="lan_en"><a href="#/EN" class="lanac">EN</a></div>
                        </div>
                    </li>
                    <li class="RWD_search">
                      <div class="tpsearch"> 
                        <div class="sertxb" v-if="isSearchIndexRWD==true">
                            <label for="keywordAll" style="display: none"> Keyword </label>
                            <b-form-input
                                id="keywordAll"
                                v-model="searchKeyWordsIndexRWD"
                                placeholder="Please input your keyword here"
                                v-on:keyup.enter="searchIndexRWD"
                                class="setx"></b-form-input>
                        </div>
                        <div class="sech">
                            <a href="javascript:" 
                                @click="searchIndexRWD()" 
                                title="Start" 
                                role="button">&nbsp;</a></div>
                      </div>
                    </li>
                  </ul>
              </nav>
          </nav>
      </div>
      <!--=========================-->
    </div>
    <div v-else>
      <div class="topbar">
        <div class="tpbrbox01"></div>
        <div class="tpbrbox02"></div>
        <div class="tpbrbox03"></div>
        <div class="tpbrbox04"></div>
        <div class="tpbrbox05"></div>
        <div class="tpbrbox06"></div>
      </div>
            
      <div class="intopm">
        <div class="intpmal">
          <div class="intp_left">
            <div class="intntulogo"><img src="/images/ntulogo.svg" alt=""/></div>
            <h1 class="intslogo"><a href="#/EN"><img src="/images/inslogo_EN.svg" alt=""/></a></h1>
          </div>
          <div class="intp_right">
            <div class="tpqlnk">
                <span><a href="#/EN/Sitemap">Sitemap</a></span>｜<span><a href="https://www.ntu.edu.tw/english/" target="_blank" title="National Taiwan University(open a new window)">National Taiwan University</a></span>
            </div>

            <nav class="menu">
              <ul>
                  <li :class="'menu01'+(sider.index==1?' mnac ':'') "><a href="#/EN/Content?type=17">Main</a></li>
                  <li v-if="newsList.length > 0" :class="'menu02'+(sider.index==2?' mnac ':'') "><a href="#/EN/News">Latest News</a></li>
                  <li :class="'menu03'+(sider.index==3?' mnac ':'') "><a href="#/EN/About?type=20">About EHS</a></li>
                  <li :class="'menu04'+(sider.index==4?' mnac ':'') "><a href="#/EN/Workgroup?type=21">Work Group</a></li>
              </ul>
              <div class="tpsearch"> 
                <div class="sertxb" v-if="isSearch==true">
                    <label for="keywordAll" style="display: none"> Keyword </label>
                    <b-form-input
                      id="keywordAll"
                      v-model="searchKeyWords"
                      placeholder="Please input your keyword here"
                      v-on:keyup.enter="search"
                      class="setx"
                      ></b-form-input>
                </div>
                <div class="sech">
                    <a href="javascript:" 
                        @click="search()" 
                        title="Start" 
                        role="button">&nbsp;</a></div>
              </div>
              <div class="language">
                  <div class="lan_zh"><a href="#/">中</a></div>
                  <div class="lan_en"><a href="#/EN" class="lanac">EN</a></div>
              </div>
            </nav>
              
            <!--========== 手機選單 ==========-->
            <div class="tright">
                <div id="trigger-overlay" class="in-burger"><span></span></div>
            </div>

            <div class="overlay overlay-hugeinc RWD_mnuopen">
              <button type="button" class="overlay-close">Close</button>
              <nav>
                  <nav>
                      <ul> 
                          <li><a href="#/EN/Content?type=17">Main</a></li>
                          <li v-if="newsList.length > 0"><a href="#/EN/News">Latest News</a></li>
                          <li><a href="#/EN/About?type=20">About EHS</a></li>
                          <li><a href="#/EN/Workgroup?type=21">Work Group</a></li>
                          <li class="RWD_lang">
                              <div class="language">
                                  <div class="lan_zh"><a href="#/">中</a></div>
                                  <div class="lan_en"><a href="#/EN" class="lanac">EN</a></div>
                              </div>
                          </li>
                          <li class="RWD_search">
                            <div class="tpsearch" v-if="isSearchRWD==true"> 
                              <div class="sertxb">
                                <label for="keywordRWD" style="display: none"> KeyWord </label>
                                <b-form-input
                                    id="keywordRWD"
                                    v-model="searchKeyWordsRWD"
                                    placeholder="Please input your keyword here"
                                    v-on:keyup.enter="searchRWD"
                                    class="setx"
                                ></b-form-input>
                              </div>
                              <div class="sech">
                                <a href="javascript:" 
                                    @click="searchRWD()" 
                                    title="Start" 
                                    role="button">&nbsp;</a></div>
                            </div>
                          </li>
                      </ul>
                    </nav>
                </nav>
            </div>
          </div>
        </div>

        <div v-if="sider.index==1" >
          <div class="intssm">
              <div class="inssmbx"
                v-for="item in option" :key="item.value">
                <a :href="'#/EN/Content?type='+item.value" 
                    :class="item.value==sider.type?'smac':''">{{ item.text }}</a></div>
          </div>
          
          <!--div class="RWD_intssm">
            <b-form-select
                v-model="rwd.type"
                :options="option"
                @change="setMainOptions(rwd.type)" 
                class="rwsel"
            ></b-form-select>
          </div-->
        </div>
          
      </div>
          
      <div class="sitmap">
        <a href="#/EN" @click="goHome">
          <img src="/images/hico.png" alt="" align="absmiddle" />Home
        </a>
        <span v-for="(item, index) in sider.list" :key="index">
         / <a :href="item.url==''?'javascript:void(0)':item.url">{{ item.text }}</a>
        </span>
      </div>          
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isSearchIndex: false,
      searchKeyWordsIndex: null,
      isSearchIndexRWD: false,
      searchKeyWordsIndexRWD: null,
      isSearch: false,
      searchKeyWords: null,
      isSearchRWD: false,
      searchKeyWordsRWD: null,
      rwd: {
        type: 0,
      },
      pagination: {
        perPage: 5,
        page: 1,
      },
      newsList: [],
    };
  },
  mounted() {
    this.init();
  },
  watch: {},
  props: ["sider", "option"],
  methods: {
    async init() {
      this.isSearchIndex = false;
      this.isSearchIndexRWD = false;
      this.isSearch = false;
      this.isSearchRWD = false;
      this.searchKeyWordsIndex = null;
      this.searchKeyWordsIndexRWD = null;
      this.searchKeyWords = null;
      this.searchKeyWordsRWD = null;

      try {
        this.newsList = [];
        let jsonReq = {
          TYPE: "HOME_LIST",
          content_SELECT: {
            pageIndex: this.pagination.page,
            pageSize: this.pagination.perPage,
            contentTypeId: 4,
          },
        };
        let resToken = await this.userRequest.post(
          "Eshntu/Token"
        ); 
        jsonReq.Token = resToken.data;
        let res = await this.userRequest.post(
          "Eshntu/Content",
          jsonReq
        );
        let json = JSON.parse(res.data);
        if (json.Status) {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            
            this.newsList = jsonData.outData;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    goHome() {
      this._go("#/EN");
    },
    searchIndex() {
      if(!this.isSearchIndex) {
        this.init();
        this.isSearchIndex = true;
      } else
        this.functionSearch(this.searchKeyWordsIndex);
    },
    searchIndexRWD() {
      if(!this.isSearchIndexRWD) {
        this.init();
        this.isSearchIndexRWD = true;
      } else
      this.functionSearch(this.searchKeyWordsIndexRWD);
    },
    search() {
      if(!this.isSearch) {
        this.init();
        this.isSearch = true;
      } else
      this.functionSearch(this.searchKeyWords);
    },
    searchRWD() {
      if(!this.isSearchRWD) {
        this.init();
        this.isSearchRWD = true;
      } else
      this.functionSearch(this.searchKeyWordsRWD);
    },
    functionSearch(data){
      if(data==null) {
        alert("Please input your keyword!");
      } else {
        window.open(
          "https://www.google.com/search?q=" +
            data +
            "site:esh.ntu.edu.tw",
          "_blank"
        );
      }
    },
    setMainOptions(type) {
        this._go("?type="+type);
    },
  },
};
</script>