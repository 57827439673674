<script>
  export default {
    name: "Mixin",
    data() {
      return {
        userRequest: this.$axios.create({ baseURL: "/api" }), 
        apiToken: (data) => this.userRequest.post("Eshntu/Token", data),
        TokenID: null,
        page: "",
        permission: null,
        isPermission: false,
        fontSizeDefault: 1.2,
        fontSize: "font-size:1.2rem !important;",
        apiPROJECT: (data) => this.userRequest.post("Eshntu/PROJECT", data),
      };
    },
    watch: {
      fontSizeDefault() {
        this.fontSize = "font-size:" + this.fontSizeDefault + "rem !important;";
      },
    },
    mounted() {},
    methods: {
      getPermission(value) {
        let json = {
          TYPE: "CHECK_PERMISSION",
          PROJECT_SELECT: {
            PJ_ID: value,
          },
        };
        this.FunctionToken(this.FunctionGetPermission, json);
      },
      FunctionGetPermission(data) {
        this.apiPROJECT(data)
          .then((res) => {
            let json = JSON.parse(res.data);
            if (json.Status) {
              this.permission = JSON.parse(json.Data);
              this.isPermission = true;
            } else
              this.isPermission = false;
          })
          .catch((err) => {
            //console.log(err);
          });
      },
      FunctionToken: function(EXfunction, data_in) {
        this.apiToken({})
          .then((res) => {
            // //console.log([EXfunction, data_in])
            data_in.TokenID = this.TokenID;
            data_in.Token = res.data;
            // data_in.Page = this.page;
            EXfunction(data_in);
          })
          .catch((error) => {
            alert("發生錯誤!" + error);
          });
      },
      FunctionTokenAsync(data_in) {
        return new Promise((resolve, reject) => {
          this.apiToken({})
            .then((res) => {
              data_in.TokenID = this.TokenID;
              data_in.Token = res.data;
              //resolve(data_in);
              setTimeout(() => resolve(data_in), 1000)
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      // 千分位逗點
      toThousands(number) {
        var num = (number || 0).toString(),
          result = "";
        while (num.length > 3) {
          result = "," + num.slice(-3) + result;
          num = num.slice(0, num.length - 3);
        }
        if (num) {
          result = num + result;
        }
        return result;
      },
      yearConvert(date) {
        // 轉民國年
        if (date) {
          // //console.log(date)
          let newDate = date.split("T")[0];
          newDate = newDate.split("-");
          let newYear = newDate[0] - 1911;
          newDate = newYear + "/" + newDate[1] + "/" + newDate[2];
          return newDate;
        }
        return "-";
      },
      dateFormat(date) {
        // 西元年日期格式
        if (date) {
          date = date.split("T")[0];
          date = date.replaceAll("-", "/");
          return date;
        } else {
          return "";
        }
      },
      dateFormatFloat(date) {
        // 西元年日期格式
        if (date) {
          date = date.split("T")[0];
          date = date.replaceAll("-", ".").replaceAll("/", ".");
          return date;
        } else {
          return "";
        }
      },
      getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
      },

      //===============================================================================
      Str_Replace(str, if1, if2) {
        return (str + "").replace(new RegExp(if1, "g"), if2);
      },
      StrCap(str1, n1, n2) {
        if (n2 < 0) {
          return str1.substr(n1, str1.length + n2);
        } else {
          return str1.substr(n1, n2);
        }
      },
      DeepCopy(data, if1 = 0) {
        if (if1 == 0) {
          return JSON.parse(JSON.stringify(data));
        }
        /*
      else if(if1==1){
        return $.extend(true, {}, data);;
      }
      */
      },
      IsNull(data, err = "") {
        if (data == "" || data == null || data == undefined) {
          return err;
        } else {
          return data;
        }
      },
      isFloat(n) {
        return n === n && n !== (n | 0);
      },
      _localData(key, val = undefined) {
        if (val == undefined) {
          return localStorage.getItem(key);
        } else if (val == "") {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, val);
        }
      },
      _sessionData(key, val = undefined) {
        if (val == undefined) {
          return sessionStorage.getItem(key);
        } else if (val == "") {
          sessionStorage.removeItem(key);
        } else {
          sessionStorage.setItem(key, val);
        }
      },
      _go(url) {
        if (isNaN(url)) {
          this.$router.push(url);
        } else {
          this.$router.go(url);
        }
      },
      _go1(url, if1 = 0, name = "") {
        if (if1 == 0) {
          window.location.href = url;
        } else if (if1 == 1) {
          window.open(url);
        } else if (if1 == 2) {
          window.open(url, name, "height=768,width=1024");
        } else if (if1 == 3) {
          if (this.windowOpen != "") {
            this.windowOpen.opener = null;
            this.windowOpen.close();
          }
          this.windowOpen = window.open(url, "test", "height=768,width=1024");
        }
      },
      _go2(json, type = "params") {
        /*
      {
        name:'test1',
        path:'/test1',
        data:{},
        key:'www',
      }
      */
        if (type == "params") {
          if (this.IsNull(json.key) != "") {
            return this.$route.params[json.key];
          } else {
            this.$router.push({
              name: json.name,
              params: json.data,
            });
          }
        } else if (type == "query") {
          if (this.IsNull(json.key) != "") {
            return this.$route.query[json.key];
          } else {
            this.$router.push({
              path: json.path,
              query: json.data,
            });
          }
        }
      },
      Str_Blank(str) {
        return this.Str_Replace(
          this.Str_Replace(this.Str_Replace(str, " ", ""), "\t", ""),
          "\r",
          ""
        );
      },
      keyupNum(data, key) {
        //限定只輸入數字
        if (isNaN(Number(data[key]))) {
          data[key] = "";
        }
      },
      numAdd0(num, len) {
        let n1 = len - (num + "").length;
        if (n1 <= 0) {
          return num;
        }
        let str = "";
        for (let i = 0; i < n1; i++) {
          str += "0";
        }
        return str + (num + "");
      },
      decryptcontent(data) {
        if (data != null || data != undefined) {
          let test = this.CryptoJS.AES.decrypt(
            data,
            "Secret Passphrase"
          ).toString(this.CryptoJS.enc.Utf8);
          return test;
        } else {
          return "";
        }
      },
      changePaged() {
        var maxPage = Math.ceil(this.pagination.rows/this.pagination.perPage);
        if(this.paginationKeyPage<1 || this.paginationKeyPage>maxPage) {
          this.paginationKeyPage = 1;
          alert("頁數請能填寫1~"+maxPage);
        }
        else this.pagination.page = this.paginationKeyPage;
      },
      showPwdEye() {
        if(this.password!=null)
        {
          if(this.password.show) {        
            this.password.show = false;
            this.password.image = "eye2.png";
            this.password.type = "text";
          } else {
            this.password.show = true;
            this.password.image = "eye.png";
            this.password.type = "password";
          }
        }
      }, 
      CloseWin() {
        if (navigator.userAgent.indexOf("Firefox")!=-1 || navigator.userAgent.indexOf("Chrome")!=-1) {
          window.location.href = "about:blank";
          window.close();
        } else {
          window.opener = null;
          window.open("", "_self");
          window.close();
        }
      },
      GetUUID() {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
          d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
      },
      generatePassword(min,max) {
        let pwd = "";
        let passwordLength = Math.floor(Math.random() * (max - min)) + min;
        let UPPERcharacters = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
        let characters = "abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ";
        characters += "0123456789";
        for(let i = 0; i < passwordLength; i++) {
          if(i==0) {
            pwd += UPPERcharacters.charAt(Math.floor(Math.random() * UPPERcharacters.length));
          } else {
            pwd += characters.charAt(Math.floor(Math.random() * characters.length));
          }
        }
        return pwd;
      },
      copyCuponCode(text, msg) {
        if(document.execCommand('copy')) {
            let copyInput = document.createElement('input');
            copyInput.value = text;
            document.body.appendChild(copyInput);
            copyInput.select();
            return new Promise((res, rej) => {
              document.execCommand('copy') ? res() : rej();
              copyInput.remove();
              
              this.$bvToast.toast('您已成功複製'+msg, {
                  title: "複製",
                  solid: true,
                });
            });
        } else if(navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
          return navigator.clipboard.writeText(text)
                  .then(() => {
                    this.$bvToast.toast('您已成功複製'+msg, {
                        title: "複製",
                        solid: true,
                      });
                  })
        }
      },
    },
  };
</script>
